<script setup lang="ts">
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { type MessageSchema } from '@/i18n'
import Button from 'primevue/button'
import { useRouter } from 'vue-router'

const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })

const router = useRouter()
const route = useRoute()

// useRoute().meta.headerConfig.headerLabel = t('pageTitle.email-verified')
</script>

<template>
  <main>
    <div v-if="route.query.verified">
      <p>{{ t('auth.emailVerification.success') }}</p>
      <Button
        :label="t('auth.emailVerification.button')"
        class="mt-4"
        @click="router.push({ name: 'home' })"
      ></Button>
    </div>
  </main>
</template>
