<script setup lang="ts">
import { ref, onMounted, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useFetch } from '@/composables/useFetch'
import { useEnvStore } from '@/stores/envStore'
import Dropdown from 'primevue/dropdown'

interface Country {
  name: string
  alpha2_code: string
  iso_code: string
  currency_symbol: string
  flag_emoji: string
}

const props = defineProps<{
  modelValue: string
  invalid?: boolean
}>()

const emit = defineEmits(['update:modelValue'])

const { t } = useI18n()
const { fetchData } = useFetch()
const countries = ref<Country[]>([])
const selectedCountry = ref<Country | null>(null)

onMounted(async () => {
  const { data } = await fetchData(useEnvStore().apiUrl + '/countries')
  if (data) {
    countries.value = data as Country[]
    if (!props.modelValue) {
      emit('update:modelValue', 'CH')
    }
    // Set initial selected country based on modelValue
    selectedCountry.value = countries.value.find(c => c.alpha2_code === props.modelValue) || null
  }
})

// Watch for changes in selectedCountry and emit alpha2_code
watch(selectedCountry, (newValue) => {
  if (newValue) {
    emit('update:modelValue', newValue.alpha2_code)
  }
})

// Watch for external modelValue changes
watch(() => props.modelValue, (newValue) => {
  selectedCountry.value = countries.value.find(c => c.alpha2_code === newValue) || null
})
</script>

<template>
  <Dropdown v-model="selectedCountry" :options="countries" filter optionLabel="name" placeholder="Select a Country"
    class="w-full md:w-14rem">
    <template #value="slotProps">
      <div v-if="slotProps.value" class="flex align-items-center">
        <span class="mr-2">{{ slotProps.value.flag_emoji }}</span>
        <div>{{ slotProps.value.name }}</div>
        <span class="ml-2">({{ slotProps.value.currency_symbol }})</span>
      </div>
      <span v-else>
        {{ slotProps.placeholder }}
      </span>
    </template>
    <template #option="slotProps">
      <div class="flex align-items-center">
        <span class="mr-2">{{ slotProps.option.flag_emoji }}</span>
        <div>{{ slotProps.option.name }}</div>
        <span class="ml-2">({{ slotProps.option.currency_symbol }})</span>
      </div>
    </template>
  </Dropdown>
</template>
