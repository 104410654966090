<script setup lang="ts">
import { ref, onMounted, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useEnvStore } from '@/stores/envStore'
import { useI18n } from 'vue-i18n'
import type { MessageSchema } from '@/i18n'
import Dropdown from 'primevue/dropdown'
import { useAppStore } from '@/stores/appStore'

const route = useRoute()
const router = useRouter()
const envStore = useEnvStore()
const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })
const appStore = useAppStore()

const languagesList = ref<{ label: string; value: 'fr' | 'en' | 'de' | 'it' }[]>([
  { label: 'Français', value: 'fr' },
  { label: 'English', value: 'en' },
  { label: 'Deutsch', value: 'de' },
  { label: 'Italiano', value: 'it' },
])

const selectedLanguage = ref(
  languagesList.value.find((language) => language.value === route.params.locale) || languagesList.value[0]
)

function updateLanguage() {
  console.log("Update language")
  console.log("Selected language", selectedLanguage.value)
  const newLanguage = selectedLanguage.value.value
  console.log("New language", newLanguage)
  route.params.locale = newLanguage;
  // console.log("Current path", currentPath)
  // const newPath = currentPath.replace(route.params.locale as string, newLanguage)
  // console.log("New path", newPath)
  // router.
}

// Watch for route changes to update the language dropdown
watch(
  () => route.params.locale,
  (newLocale) => {
    if (typeof newLocale === 'string') {
      selectedLanguage.value = languagesList.value.find((lang) => lang.value === newLocale) || languagesList.value[0]
      appStore.setLanguage(newLocale as 'fr' | 'en' | 'de' | 'it')
    }
  }
)

onMounted(() => {
  const locale = route.params.locale as string
  if (locale) {
    selectedLanguage.value = languagesList.value.find((lang) => lang.value === locale) || languagesList.value[0]
    appStore.setLanguage(selectedLanguage.value.value as 'en' | 'fr' | 'de' | 'it')

  }
})
</script>

<template>
  <main class="max-w-4xl mx-auto p-4">
    <!-- <div class="flex justify-end mb-4">
      <Dropdown class="w-40" :options="languagesList" optionLabel="label" v-model="selectedLanguage"
        @change="updateLanguage">
        <template #value="slotProps">
          {{ slotProps.value.label }}
        </template>
        <template #option="slotProps">
          {{ slotProps.option.label }}
        </template>
      </Dropdown>
    </div> -->

    <iframe
      :src="`${envStore.apiUrl}/conditions-generales/${route.params.locale}`"
      class="w-full h-[800px]"
      frameborder="0"
    ></iframe>
  </main>
</template>
