import { defineStore } from 'pinia'
import { ref, type Ref } from 'vue'

// export const useDishesStore = defineStore('dishes', {
export const useEnvStore = defineStore('env', () => {
  const apiUrl: Ref<string> = ref(
    import.meta.env.VITE_MODE === 'local'
      ? 'http://localhost:8000/api'
      : import.meta.env.VITE_API_URL
  )
  const environment = ref(
    import.meta.env.VITE_MODE ? import.meta.env.VITE_MODE : import.meta.env.MODE
  )
  const lastUpdate = ref(import.meta.env.VITE_APP_LAST_UPDATE)
  const appVersion = ref(import.meta.env.VITE_APP_VERSION)
  const imgGenApiUrl = ref(import.meta.env.VITE_IMAGE_GEN_API_URL)
  const imgGenApiKey = ref(import.meta.env.VITE_IMAGE_GEN_API_KEY)
  const defaultLocale = ref(import.meta.env.VITE_DEFAULT_LOCALE);
  const googleAnalyticsMeasurementId = ref(import.meta.env.VITE_GOOGLE_ANALYTICS_MEASUREMENT_ID);
  const googleClientId = ref(import.meta.env.VITE_GOOGLE_CLIENT_ID);

  return { apiUrl, environment, lastUpdate, appVersion, imgGenApiKey, imgGenApiUrl, defaultLocale, googleAnalyticsMeasurementId, googleClientId }
})
