<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { setLocale, type MessageSchema } from '@/i18n/index.js'
import InputText from 'primevue/inputtext'
import { ref, type Ref, computed, reactive } from 'vue'
import { useToast } from 'primevue/usetoast'
import type { User, UserLoginRequest } from '@/types/api.js'
import Button from 'primevue/button'
import Dropdown from 'primevue/dropdown'
import { useProfileProfile } from '@/composables/useProfileProfile.js'
import { useAuth } from '@/composables/useAuth'
import { useAppStore } from '@/stores/appStore'
import { watch } from 'vue'
import { useUserStore } from '@/stores/useUserStore'
import Dialog from 'primevue/dialog'
import Password from 'primevue/password'
import InlineMessage from '@/components/InlineMessage.vue'
import CountrySelect from './CountrySelect.vue'

const user = ref(useUserStore().user)

const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })

const toast = useToast()
const { logout, isFetching, login } = useAuth()

const { updateUserProperty, updatePassword } = useProfileProfile(toast)
const appStore = useAppStore();

// const languagesList = ref(['french', 'english', 'german', 'italian'])
const languagesList = ref<{ label: string; value: 'fr' | 'en' | 'de' | 'it' }[]>([
  { label: 'Français', value: 'fr' },
  { label: 'English', value: 'en' },
  { label: 'Deutsch', value: 'de' },
  { label: 'Italiano', value: 'it' },
])

const selectedCountry = ref(user.value?.country_alpha2_code ?? 'ch')
const firstName = ref(user.value?.first_name || '')
const lastName = ref(user.value?.last_name || '')
const email = ref(user.value?.email || '')
const selectedLanguage = ref(
  languagesList.value.find((language) => language.value === appStore.language) || languagesList.value[0]
)

async function updateProperty(propertyName: string, value: string | number) {
  if (!user.value) return // Make sure the user is defined

  const updateResponse = await updateUserProperty(propertyName, value)
  if (updateResponse !== null) {
    // Update the local user state
    user.value = updateResponse
    // Also update the reactive references for form fields
    if (propertyName === 'first_name') firstName.value = updateResponse.first_name
    if (propertyName === 'last_name') lastName.value = updateResponse.last_name
    if (propertyName === 'email') email.value = updateResponse.email
  }
}

function updateLanguage() {
  const newLanguage = selectedLanguage.value.value

  appStore.setLanguage(newLanguage)
  updateProperty('language', newLanguage)
}


watch(selectedCountry, (newCountry, oldCountry) => {
  if (newCountry !== oldCountry) {
    updateProperty('country_alpha2_code', newCountry)
  }
})

// Watch for changes in the language from the store and update the i18n locale accordingly
watch(
  () => appStore.language as 'fr' | 'en' | 'de' | 'it',
  (newLanguage) => {
    setLocale(newLanguage)
  }
)

const openChangePasswordModal = ref(false)
const currentPassword = ref('')
const newPassword = ref('')
const confirmNewPassword = ref('')
const isFetchingPassword = ref(false)
const touchedPasswordFields = reactive({
  currentPassword: false,
  newPassword: false,
  confirmNewPassword: false,
})

const isNewPasswordValid = computed(() => {
  return !touchedPasswordFields.newPassword || newPassword.value.length >= 8
})

const isConfirmNewPasswordValid = computed(() => {
  return !touchedPasswordFields.confirmNewPassword || newPassword.value === confirmNewPassword.value
})

const isFormValid = computed(() => {
  return (
    touchedPasswordFields.currentPassword &&
    touchedPasswordFields.newPassword &&
    touchedPasswordFields.confirmNewPassword &&
    isNewPasswordValid.value &&
    isConfirmNewPasswordValid.value
  )
})

async function submitPasswordChange() {
  isFetchingPassword.value = true
  const success = await updatePassword(currentPassword.value, newPassword.value, confirmNewPassword.value)
  isFetchingPassword.value = false

  if (success) {
    // Optionally, you can clear the password fields
    currentPassword.value = ''
    newPassword.value = ''
    confirmNewPassword.value = ''
    touchedPasswordFields.currentPassword = false
    touchedPasswordFields.newPassword = false
    touchedPasswordFields.confirmNewPassword = false

    // Close the dialog
    openChangePasswordModal.value = false
    // Log the user out and redirect to login page
    await logout()
  }
}
</script>

<template>
  <div class="flex flex-col gap-6">
    <div>
      <h3>{{ t('profile.profile.profileTab.personnalData.title') }}</h3>
      <h4 class="mt-2">{{ t('profile.profile.profileTab.personnalData.firstName') }}</h4>
      <InputText class="w-full" v-model="firstName" @blur="updateProperty('first_name', firstName)" />
      <h4 class="mt-2">{{ t('profile.profile.profileTab.personnalData.lastName') }}</h4>
      <InputText class="w-full" v-model="lastName" @blur="updateProperty('last_name', lastName)" />
    </div>

    <div>
      <h3>{{ t('profile.profile.profileTab.language.title') }}</h3>
      <Dropdown class="w-1/2" :options="languagesList" optionLabel="label" v-model="selectedLanguage"
        @change="updateLanguage">
        <template #value="slotProps">
          {{ slotProps.value.label }}
        </template>
        <template #option="slotProps">
          {{ slotProps.option.label }}
        </template>
      </Dropdown>
    </div>

    <div>
      <h4 class="mt-2">{{ t('auth.user.form.label.country') }}</h4>
      <CountrySelect v-model="selectedCountry" />
    </div>

    <div>
      <h3>{{ t('profile.profile.profileTab.passwordAndEmail.title') }}</h3>
      <h4 class="mt-2">{{ t('profile.profile.profileTab.passwordAndEmail.email') }}</h4>
      <p>{{ email }}</p>
      <!-- <InputText class="w-full" v-model="email" @blur="updateProperty('email', email)" disabled /> -->
      <h4 class="mt-2">{{ t('profile.profile.profileTab.passwordAndEmail.password') }}</h4>
      <Button class="mt-1" @click="openChangePasswordModal = true">
        {{ t('profile.profile.profileTab.passwordAndEmail.changePassword') }}
      </Button>
    </div>
    <Button :label="t('auth.logout')" class="w-full mt-8" @click="logout" :loading="isFetching" />
    <Dialog v-model:visible="openChangePasswordModal" modal
      :header="t('profile.profile.profileTab.passwordAndEmail.changePassword')" :style="{ width: '25rem' }">
      <form @submit.prevent="submitPasswordChange" class="flex flex-col gap-4">
        <p></p>
        <div class="p-3 mb-3 bg-primary-100 border border-primary-400 text-primary-700 rounded">
          <p><i class="pi pi-info-circle"></i> {{ t('profile.profile.profileTab.passwordAndEmail.logoutNotice') }}</p>
        </div>
        <div class="flex flex-col gap-2">
          <label for="currentPassword">{{ t('auth.user.form.label.current_password') }}</label>
          <Password id="currentPassword" v-model="currentPassword" class="w-full"
            @blur="touchedPasswordFields.currentPassword = true" />
        </div>
        <div class="flex flex-col gap-2">
          <label for="newPassword">{{ t('auth.user.form.label.password') }}</label>
          <Password id="newPassword" v-model="newPassword" class="w-full"
            @blur="touchedPasswordFields.newPassword = true" />
          <InlineMessage v-if="!isNewPasswordValid" severity="error">{{ t('auth.user.form.error.invalid_password') }}
          </InlineMessage>
        </div>
        <div class="flex flex-col gap-2">
          <label for="confirmNewPassword">{{ t('auth.user.form.label.password_confirmation') }}</label>
          <Password id="confirmNewPassword" v-model="confirmNewPassword" class="w-full"
            @blur="touchedPasswordFields.confirmNewPassword = true" />
          <InlineMessage v-if="!isConfirmNewPasswordValid" severity="error">{{
            t('auth.user.form.error.invalid_password_confirmation') }}</InlineMessage>
        </div>
        <Button :label="t('common.edit')" type="submit" :loading="isFetchingPassword" :disabled="!isFormValid" />
      </form>
    </Dialog>
  </div>
</template>
