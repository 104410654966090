<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { type MessageSchema } from '@/i18n'
import {
  useDishCreationEditionFormStore,
  useDishesStore,
  useRecipeStore,
} from '@/stores/dishesStore'
import { useEnvStore } from '@/stores/envStore'
import { useFetch } from '@/composables/useFetch'
import Textarea from 'primevue/textarea'
import Button from 'primevue/button'
import Slider from 'primevue/slider'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import { computed, onMounted, ref, watchEffect } from 'vue'
import InputText from 'primevue/inputtext'
import InputNumber from '@/components/BaseComponents/InputNumber.vue'
import { useRouter } from 'vue-router'
import { useToast } from 'primevue/usetoast'
import type { Ref } from 'vue'
import type { DishHistoryState } from '@/types/history.js'
import EditIngredient from '@/components/Orders/EditIngredient.vue'
import type { Ingredient } from '@/types/dish'
import { adjustUnitsForQuantity, calculateIngredientPrice } from '@/utils/prices'
import { useConfirm } from 'primevue/useconfirm'

const props = defineProps<{
  dishEdition: boolean
}>()

const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })
const confirm = useConfirm()

const router = useRouter()
const toast = useToast()
const recipe = useDishCreationEditionFormStore().creationEditionDish
const { fetchData, isFetching } = useFetch()
const ingredientEdition = ref(true)
const showSidebar = ref(false)

const sideBarIngredient: Ref<Ingredient> = ref({
  id: -1,
  category: 'other',
  name: '',
  quantity: 0,
  unit: '',
  price: 0,
})
const sideBarIngredientIndex = ref(0)

const newServings = ref(recipe?.dish_data.servings || 1)

const displayedIngredients = computed(() => {
  if (!recipe?.dish_data) return [];

  const servings = recipe.dish_data.servings || 1;

  return recipe.dish_data.ingredients.map((ingredient: Ingredient) => {
    let converted = { unit: ingredient.unit || '', quantity: ingredient.quantity || 0 }
    converted = adjustUnitsForQuantity(converted.quantity, converted.unit, servings);

    return {
      ...ingredient,
      quantity: converted.quantity,
      unit: converted.unit,
      price: calculateIngredientPrice(ingredient.price, servings)
    };
  }).sort((a, b) => a.id - b.id);
});

/**
 * Save the dish in the database
 */
async function saveDish() {
  if (!recipe) return

  const originalServings = recipe.dish_data.servings || 1

  const recipeToSave = { ...recipe }
  recipeToSave.dish_data.servings = newServings.value


  recipeToSave.dish_data.ingredients.forEach((ingredient) => {
    if (ingredient.category === '') ingredient.category = 'other'
    if (ingredient.quantity != null) {
      ingredient.quantity *= originalServings;
    } else {
      ingredient.quantity = 0;
    }
    if (ingredient.price != null) {
      ingredient.price *= originalServings;
    } else {
      ingredient.price = 0;
    }
  })

  // Remove the possible empty steps
  recipeToSave.dish_data.steps.forEach((step) => {
    step.instructions = step.instructions.filter((instruction) => instruction !== '')
  })

  // Prepare fetch
  const url = props.dishEdition ? `/dishes/${recipeToSave.dish_data.id}` : '/dishes/import/save'
  const method = props.dishEdition ? 'PUT' : 'POST'
  console.log('Saving dish...')

  fetchData(`${useEnvStore().apiUrl}${url}`, method, recipeToSave).then(async ({ data, error }) => {
    if (error) {
      console.error('Upload failed:', error)
      toast.add({
        severity: 'error',
        summary: t('common.error'),
        detail: props.dishEdition
          ? t('profileFavorites.editError')
          : t('profileFavorites.addError'),
        life: 3000,
      })
    } else {
      console.log('Upload successful:')
      console.log(data.name)
      // toast.add({
      //   severity: 'success',
      //   summary: t('common.success'),
      //   detail: props.dishEdition
      //     ? t('profileFavorites.editSucces')
      //     : t('profileFavorites.addSucces'),
      //   life: 3000,
      // })

      const { data: recipeToSave, error: recipeError } = await fetchData(
        useEnvStore().apiUrl + `/dishes/${data.id}/recipe`
      )
      if (recipeError) {
        console.error(error)
      } else {
        console.log(recipeToSave)
        useRecipeStore().recipe = recipeToSave
      }

      useDishesStore().selectedDish = data

      const savedState = sessionStorage.getItem('historyState')

      if (savedState) {
        let state: DishHistoryState = JSON.parse(savedState)
        const editedDishIndex = state.dishes.findIndex((dish) => dish.id === data.id)
        state.dishes[editedDishIndex] = data
        sessionStorage.setItem('historyState', JSON.stringify(state))
      }

      router.back()
    }
  })
}

// Add new ingredient to the list
function addIngredient() {
  const tempId = Date.now() + Math.floor(Math.random() * 1000)
  sideBarIngredient.value.id = tempId

  const servings = recipe?.dish_data.servings || 1
  
  sideBarIngredient.value.quantity = sideBarIngredient.value.quantity != null ? sideBarIngredient.value.quantity / servings : 0;
  sideBarIngredient.value.price = sideBarIngredient.value.price != null ? sideBarIngredient.value.price / servings : 0;
  // recipe?.dish_data.ingredients.push(sideBarIngredient.value)
  // showSidebar.value = false
  // Adjust the ingredient for 1 serving before adding
  let adjustedIngredient = { quantity: sideBarIngredient.value.quantity, unit: sideBarIngredient.value.unit };
  // if (sideBarIngredient.value.quantity) {
  //   adjustedIngredient = adjustUnitsForQuantity(
  //     sideBarIngredient.value.quantity,
  //     sideBarIngredient.value.unit,
  //     servings
  //   );
  // }

  // Add the adjusted ingredient to the recipe
  recipe?.dish_data.ingredients.push({
    ...sideBarIngredient.value,
    quantity: adjustedIngredient.quantity,
    unit: adjustedIngredient.unit,
    price: sideBarIngredient.value.price
  });

  showSidebar.value = false;
}

// Edit existing ingredient in the list
function editIngredient() {
  if (!recipe) return
  if (sideBarIngredientIndex.value !== -1) {
    const servings = recipe.dish_data.servings || 1

    let adjustedIngredient = { quantity: sideBarIngredient.value.quantity, unit: sideBarIngredient.value.unit };
    // if (sideBarIngredient.value.quantity) {
    //   adjustedIngredient = adjustUnitsForQuantity(
    //     sideBarIngredient.value.quantity,
    //     sideBarIngredient.value.unit,
    //     1
    //   );
    // }

    
    // Create adjusted ingredient for single serving
    let adjustedSideBarIngredient = {
      ...sideBarIngredient.value,
      quantity: adjustedIngredient.quantity != null ? adjustedIngredient.quantity / servings : 0,
      unit: adjustedIngredient.unit,
      price: (sideBarIngredient.value.price != null ? sideBarIngredient.value.price : 0) / servings
    }

    // Set default price if null
    if (adjustedSideBarIngredient.price === null) adjustedSideBarIngredient.price = 0

    // Update the ingredient in recipe
    recipe.dish_data.ingredients[sideBarIngredientIndex.value] = adjustedSideBarIngredient
  }
  showSidebar.value = false
}
function deleteIngredient() {
  if (!recipe) return
  if (sideBarIngredientIndex.value !== -1) {
    recipe.dish_data.ingredients.splice(sideBarIngredientIndex.value, 1)
  }
  showSidebar.value = false
}

// Triggered when an ingredient is clicked.
function prepareEdit(ingredient: Ingredient) {
  if (!recipe) return

  const originalIndex = recipe.dish_data.ingredients.findIndex(
    (ing) => ing.id === ingredient.id
  )
  const originalIngredient = recipe.dish_data.ingredients[originalIndex]

  sideBarIngredientIndex.value = originalIndex
  const quantity = originalIngredient.quantity != null ? parseFloat((originalIngredient.quantity * recipe.dish_data.servings).toFixed(2)) : 0;
  const price = originalIngredient.price != null ? parseFloat((originalIngredient.price * recipe.dish_data.servings).toFixed(2)) : 0;
  sideBarIngredient.value = { ...originalIngredient, quantity, price }
  ingredientEdition.value = true
  showSidebar.value = true
}

function deleteStep(index: number) {
  confirm.require({
    message: t('profileFavorites.creationForm.deleteStepConfirmation'),
    header: t('common.warning'),
    icon: 'pi pi-exclamation-triangle',
    acceptClass: 'p-button-danger',
    acceptLabel: t('common.delete'),
    rejectLabel: t('common.cancel'),
    accept: () => {
      recipe?.dish_data.steps.splice(index, 1)
    },
    reject: () => {
    }
  })
}

function deleteInstruction(stepIndex: number, instructionIndex: number) {
  confirm.require({
    message: t('profileFavorites.creationForm.deleteInstructionConfirmation'),
    header: t('common.warning'),
    icon: 'pi pi-exclamation-triangle',
    acceptClass: 'p-button-danger',
    acceptLabel: t('common.delete'),
    rejectLabel: t('common.cancel'),
    accept: () => {
      recipe?.dish_data.steps[stepIndex].instructions.splice(instructionIndex, 1)
    },
    reject: () => {
      // Ne rien faire si l'utilisateur annule
    }
  })
}

function insertInstructionAfter(stepIndex: number, instructionIndex: number) {
  recipe?.dish_data.steps[stepIndex].instructions.splice(instructionIndex + 1, 0, '')
}

function insertStepAt(index: number) {
  if (!recipe) return
  recipe.dish_data.steps.splice(index, 0, { name: '', instructions: [''] })
}

onMounted(() => {
  if (props.dishEdition && !recipe?.dish_data.id) router.go(-1)
})

watchEffect(() => {
  if (recipe?.dish_data.chill_time_min === null) recipe.dish_data.chill_time_min = 0
  if (recipe?.dish_data.prep_time_min === null) recipe.dish_data.prep_time_min = 0
  if (recipe?.dish_data.cook_time_min === null) recipe.dish_data.cook_time_min = 0
})
</script>

<template>
  <main>
    <div v-if="recipe" class="flex flex-col gap-8">
      <div>
        <h2>{{ t('profileFavorites.creationForm.informations') }}</h2>
        <div class="flex flex-col gap-2">
          <div class="flex flex-col">
            <label>{{ t('profileFavorites.creationForm.recipeName') }}</label>
            <InputText v-model="recipe.dish_data.name"
              :placeholder="t('profileFavorites.creationForm.dishNamePlaceholder')" :disabled="isFetching" />
          </div>
          <div class="flex flex-col">
            <label>{{ t('profileFavorites.creationForm.numberOfPeople') }}</label>
            <InputNumber v-model="newServings" :min="1"
              :placeholder="t('profileFavorites.creationForm.servingsPlaceholder')" :disabled="isFetching" />
          </div>
          <div>
            <label>{{ t('profileFavorites.creationForm.difficulty') }}</label>
            <Slider :min="1" :max="4" :step="1" v-model:model-value="recipe.dish_data.difficulty"
              :disabled="isFetching" />
          </div>
          <div class="flex flex-col">
            <label>{{ t('profileFavorites.creationForm.prepTime') }} (min)</label>
            <InputNumber v-model="recipe.dish_data.prep_time_min"
              :placeholder="t('profileFavorites.creationForm.timePlaceholder')" :disabled="isFetching" :min="0" />
          </div>
          <div class="flex flex-col">
            <label>{{ t('profileFavorites.creationForm.chillTime') }} (min)</label>
            <InputNumber v-model="recipe.dish_data.chill_time_min"
              :placeholder="t('profileFavorites.creationForm.timePlaceholder')" :disabled="isFetching" :min="0" />
          </div>
          <div class="flex flex-col">
            <label>{{ t('profileFavorites.creationForm.cookTime') }} (min)</label>
            <InputNumber v-model="recipe.dish_data.cook_time_min"
              :placeholder="t('profileFavorites.creationForm.timePlaceholder')" :disabled="isFetching" :min="0" />
          </div>
        </div>
      </div>

      <div>
        <h2>{{ t('profileFavorites.creationForm.ingredients') }}</h2>
        <DataTable :value="displayedIngredients" size="small" @row-click="(evt) => prepareEdit(evt.data)">
          <Column field="quantity" :header="t('profileFavorites.creationForm.quantityShort')" />
          <Column field="unit" :header="t('profileFavorites.creationForm.unit')" />
          <Column field="name" :header="t('profileFavorites.creationForm.name')" />
          <Column field="price" :header="t('profileFavorites.creationForm.price')" />
        </DataTable>

        <!-- <Button :label="t('profileFavorites.creationForm.addIngredientButton')" @click="() => {
          showSidebar = true
          ingredientEdition = false
        }
          " /> -->
        <div class="hover:cursor-pointer" @click="() => {
          showSidebar = true
          ingredientEdition = false
        }
          ">
          <p>+ {{ t('profileFavorites.creationForm.addIngredientButton') }}</p>
        </div>
      </div>

      <div class="mb-20">
        <h2>{{ t('profileFavorites.creationForm.steps') }}</h2>

        <!-- Bouton pour ajouter une étape au début -->
        <div class="flex justify-left my-4">
          <!-- <Button :label="t('profileFavorites.creationForm.addStepButton')" @click="insertStepAt(0)"
            :disabled="isFetching" class="p-button-outlined" /> -->
          <h3 class="text-sm hover:cursor-pointer" @click="isFetching ? null : insertStepAt(0)">
            + {{ t('profileFavorites.creationForm.addStepButton') }}
          </h3>
        </div>

        <div v-for="(step, stepIndex) in recipe.dish_data.steps" :key="stepIndex">
          <div class="mb-4">
            <div class="flex justify-normal items-center">
              <h3>{{ t('profileFavorites.creationForm.step') }} {{ stepIndex + 1 }}</h3>
              <Button icon="pi pi-trash" severity="danger" text @click="deleteStep(stepIndex)" :disabled="isFetching" />
            </div>
            <div class="grid mb-2 md:gap-2 md:grid-cols-6">
              <b class="col-span-1">{{ t('profileFavorites.creationForm.stepTitle') }}</b>
              <div class="col-span-5">
                <div class="flex flex-col">
                  <InputText v-model="step.name" placeholder="Ex: Carottes" :disabled="isFetching" />
                </div>
              </div>
            </div>
            <div v-for="(instruction, index) in step.instructions" :key="index"
              class="grid mb-2 md:gap-2 md:grid-cols-6">
              <b class="col-span-1">
                {{ index === 0 ? t('profileFavorites.creationForm.instructions') : '' }}
              </b>
              <div class="col-span-5">
                <div class="flex gap-2 items-center">
                  <Textarea v-model="step.instructions[index]" class="w-full" :auto-resize="true"
                    :disabled="isFetching" />
                  <div class="flex gap-1">
                    <Button icon="pi pi-trash" severity="danger" text @click="deleteInstruction(stepIndex, index)"
                      :disabled="isFetching" v-tooltip.top="t('common.delete')" />
                    <Button icon="pi pi-plus" text @click="insertInstructionAfter(stepIndex, index)"
                      :disabled="isFetching" v-tooltip.top="t('profileFavorites.creationForm.addInstructionAfter')" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Bouton pour ajouter une étape après celle-ci -->
          <div class="flex justify-left my-8">
            <!-- <Button 
              :label="t('profileFavorites.creationForm.addStepHereButton')" 
              @click="insertStepAt(stepIndex + 1)"
              :disabled="isFetching" 
              class="p-button-outlined" /> -->
            <h3 class="text-sm hover:cursor-pointer" @click="isFetching ? null : insertStepAt(stepIndex + 1)">
              + {{ t('profileFavorites.creationForm.addStepHereButton') }}
            </h3>
          </div>
        </div>
      </div>

      <div class="fixed inset-x-0 bottom-0 z-50 px-4 py-3 bg-white custom-shadow">
        <Button :label="t('profileFavorites.creationForm.saveButton')" @click="saveDish" :loading="isFetching"
          class="w-full" />
      </div>
    </div>
    <EditIngredient v-model:ingredient="sideBarIngredient" :index="sideBarIngredientIndex" v-model:visible="showSidebar"
      @add-ingredient="addIngredient" @edit-ingredient="editIngredient()" @delete-ingredient="deleteIngredient"
      :showPrice="true" />

    <!-- <Sidebar v-model:visible="showSidebar"
      :header="`${ingredientEdition ? t('profileFavorites.creationForm.edit') : t('profileFavorites.creationForm.add')} un ingrédient`"
      position="bottom" style="height: auto" :block-scroll="true" @hide="resetSideBarIngredient">
      <div class="flex flex-col gap-4">
        <div class="flex flex-col">
          <label>{{ t('profileFavorites.creationForm.name') }}</label>
          <InputText v-model="sideBarIngredient.name"
            :placeholder="t('profileFavorites.creationForm.namePlaceholder')" />
        </div>
        <div class="grid grid-cols-2 gap-4">
          <div class="flex flex-col">
            <label>{{ t('profileFavorites.creationForm.quantityLong') }}</label>
            <InputNumber v-model="sideBarIngredient.quantity"
              :placeholder="t('profileFavorites.creationForm.quantityPlaceholder')" :min-fraction-digits="2" />
          </div>
          <div class="flex flex-col">
            <label>{{ t('profileFavorites.creationForm.unit') }}</label>
            <InputText v-model="sideBarIngredient.unit"
              :placeholder="t('profileFavorites.creationForm.unitPlaceholder')" />
          </div>
          <div class="flex flex-col">
            <label>{{ t('profileFavorites.creationForm.price') }}</label>
            <InputNumber v-model="sideBarIngredient.price"
              :placeholder="t('profileFavorites.creationForm.pricePlaceholder')" :min-fraction-digits="2" />
          </div>
        </div>
        <Button v-if="ingredientEdition" :label="t('profileFavorites.creationForm.deleteIngredientButton')"
          @click="deleteIngredient" severity="danger" text />
        <Button :label="
            ingredientEdition
              ? t('profileFavorites.creationForm.edit')
              : t('profileFavorites.creationForm.add')
          " @click="ingredientEdition ? editIngredient() : addIngredient()" :disabled="
            sideBarIngredient.name === '' ||
            (!sideBarIngredient.quantity && sideBarIngredient.unit !== '')
          " />
      </div>
    </Sidebar> -->
  </main>
</template>

<style scoped>
.custom-shadow {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
  /* Increase the opacity and spread for visibility */
}
</style>
