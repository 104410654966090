<script setup lang="ts">
import LoadingView from '../LoadingView.vue'
import { useFetch } from '@/composables/useFetch.js'
import { useEnvStore } from '@/stores/envStore.js'
import { useUserStore } from '@/stores/useUserStore'
import type { Order, TableRow } from '@/types/order.js'
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Button from 'primevue/button'
import { useToast } from 'primevue/usetoast'
import { useOrder } from '@/composables/useOrders'

const { fetchData } = useFetch()
const apiUrl = useEnvStore().apiUrl
const userStore = useUserStore()
const route = useRoute()
const { t } = useI18n()
const toast = useToast()
const { generatePDF } = useOrder()

const order = ref<Order | null>(null)
const isLoading = ref(true)
const restaurantId = userStore.user?.restaurants[0].id

async function fetchOrderDetails() {
  if (!restaurantId) return

  const orderId = route.params.orderId
  try {
    const { data, error } = await fetchData(
      `${apiUrl}/restaurants/${restaurantId}/orders/${orderId}`,
      'GET'
    )
    if (error) {
      console.error('Error fetching order details:', error)
    } else {
      order.value = data as Order
    }
  } catch (e) {
    console.error('Exception while fetching order details:', e)
  } finally {
    isLoading.value = false
  }
}

function formatDate(date: Date): string {
  const day = String(date.getDate()).padStart(2, '0')
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const year = date.getFullYear()
  return `${day}.${month}.${year}`
}

function formatOrderForClipboard() {
  if (!order.value) return ''
  const date = formatDate(new Date(order.value.wanted_date))

  let text = `${t('orders.orderForm.file.orderOfDate', { date: formatDate(new Date()) })} - ${userStore.user?.restaurants[0].name}\n\n`
  text += `${t('orders.orderForm.file.provider')}: ${order.value.supplier.name}\n`
  text += `${t('orders.orderForm.file.deliveryDateWanted')}: ${date}\n\n`
  text += `${t('orders.orderForm.file.ingredientsList')}:\n`
  order.value.ingredients.forEach(ingredient => {
    text += `- ${ingredient.quantity} ${ingredient.unit} ${ingredient.name}\n`
  })
  if (order.value.custom_message) {
    text += `\n${t('orders.orderForm.file.customMessage')}:\n${order.value.custom_message}`
  }
  text += `\nRestaurant: \n- ${userStore.user?.restaurants[0].name}\n- ${userStore.user?.restaurants[0].phone_number}\n`
  return text
}

async function copyOrderToClipboard() {
  const text = formatOrderForClipboard()
  try {
    await navigator.clipboard.writeText(text)
    toast.add({
      severity: 'success',
      summary: t('common.success'),
      detail: t('orders.orderForm.copy.success'),
      life: 3000,
    })
  } catch (err) {
    toast.add({
      severity: 'error',
      summary: t('common.error'),
      detail: t('orders.orderForm.copy.error'),
      life: 3000,
    })
  }
}

async function handleGeneratePDF() {
  if (!order.value) return;

  const ingredients: TableRow[] = order.value.ingredients.map(ing => ({
    type: 'ingredient',
    data: ing
  }));

  await generatePDF(
    t,
    ingredients,
    [order.value.supplier],
    order.value.supplier.uuid,
    new Date(order.value.wanted_date),
    order.value.custom_message
  )
}

onMounted(() => {
  fetchOrderDetails()
})
</script>

<template>
  <main class="p-4">
    <LoadingView v-if="isLoading" />
    <div v-else-if="order" class="space-y-4">
      <h1 class="text-2xl font-bold mb-4">{{ t('orders.orderNb', { orderNb: order.id }) }}</h1>

      <div>
        <h3 class="font-semibold">{{ t('orders.orderCreatedAt') }}</h3>
        <p>{{ formatDate(new Date(order.created_at)) }}</p>
      </div>

      <div>
        <h3 class="font-semibold">{{ t('orders.orderForm.supplier') }}</h3>
        <p>{{ order.supplier.name }}</p>
      </div>

      <div>
        <h3 class="font-semibold">{{ t('orders.orderForm.wantedDate') }}</h3>
        <p>{{ formatDate(new Date(order.wanted_date)) }}</p>
      </div>

      <div v-if="order.custom_message">
        <h3 class="font-semibold">{{ t('orders.orderForm.customMessage') }}</h3>
        <p>{{ order.custom_message }}</p>
      </div>

      <div>
        <h3 class="font-semibold">{{ t('orders.orderForm.shippingList') }}</h3>
        <DataTable :value="order.ingredients">
          <Column field="quantity" :header="t('orders.orderForm.quantityShort')" />
          <Column field="unit" :header="t('orders.orderForm.unit')" />
          <Column field="name" :header="t('orders.orderForm.name')" />
        </DataTable>
      </div>

      <div class="flex gap-2">
        <Button icon="pi pi-copy" @click="copyOrderToClipboard" :tooltip="t('orders.orderForm.copy.tooltip')"
          class="p-button-outlined" />
        <Button icon="pi pi-file-pdf" @click="handleGeneratePDF" :tooltip="t('orders.orderForm.pdf.tooltip')"
          class="p-button-outlined p-button-secondary" />
      </div>
    </div>
  </main>
</template>
