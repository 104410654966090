<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { type MessageSchema } from '@/i18n'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import { computed, onMounted, reactive, ref, type Ref } from 'vue'
import InlineLink from '@/components/InlineLink.vue'
import InlineMessage from '@/components/InlineMessage.vue'
import { useAuth } from '@/composables/useAuth.js'
import { useFetch } from '@/composables/useFetch.js'
import { useEnvStore } from '@/stores/envStore.js'
import type { Attribute } from '@/types/restaurants.js'
import RadioButton from 'primevue/radiobutton'
import { useToast } from 'primevue/usetoast'
import { useAppStore } from '@/stores/appStore'
import Dropdown from 'primevue/dropdown'
import Checkbox from 'primevue/checkbox'
import CountrySelect from '@/components/Profile/MyProfile/CountrySelect.vue'
import Avatar from '@/assets/icons/Avatars/Avatar.vue';
import { useUserStore } from '@/stores/useUserStore'


const { validateStepTwoAuth, errors, isFetching } = useAuth()
const { fetchData } = useFetch()
const toast = useToast()
const appStore = useAppStore();
const userStore = useUserStore()
const user = userStore.user


errors.value = {}

const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })

const restaurationTypes: Ref<Attribute[]> = ref([])

const userForm = ref({
  restaurant_name: '',
  is_private: true,
  restauration_type_id: 0,
  language: appStore.language,
  country: 'CH',
})

function hasError(fieldName: string) {
  return !!errors.value[fieldName] && errors.value[fieldName].length > 0
}

const touched = reactive({
  restaurant_name: false,
  restauration_type_id: false,
  country: false,
})
const isRestaurantNameValid = computed(() => {
  if (!touched.restaurant_name) return true
  return touched.restaurant_name && userForm.value.restaurant_name
})

const isRestaurationTypeIdValid = computed(() => {
  if (!touched.restauration_type_id) return true
  return touched.restauration_type_id && userForm.value.restauration_type_id
})

const isCountryValid = computed(() => {
  if (!touched.country) return true
  return touched.country && userForm.value.country
})

const isAChef = ref(false)

const acceptedCGU = ref(false)


async function submitForm() {

  const restaurantUuid = user?.restaurants[0].id
  const restaurantUuidShort = restaurantUuid?.substring(0, 4)
  userForm.value.is_private = !isAChef.value;
  if (!isAChef.value) {
    userForm.value.restaurant_name = `restaurant-${restaurantUuidShort}`
    userForm.value.restauration_type_id = restaurationTypes.value[0].id
  }
  await validateStepTwoAuth(userForm.value, toast);
}

const languagesList = ref<{ label: string; value: 'fr' | 'en' | 'de' | 'it' }[]>([
  { label: 'Français', value: 'fr' },
  { label: 'English', value: 'en' },
  { label: 'Deutsch', value: 'de' },
  { label: 'Italiano', value: 'it' },
])

const selectedLanguage = ref(
  languagesList.value.find((language) => language.value === appStore.language) || languagesList.value[0]
)

function updateLanguage() {
  const newLanguage = selectedLanguage.value.value
  appStore.setLanguage(newLanguage)
}

onMounted(async () => {
  const { data } = await fetchData(useEnvStore().apiUrl + '/attributes/restauration-types')
  if (data) {
    restaurationTypes.value = data as Attribute[]
    userForm.value.restauration_type_id = restaurationTypes.value[0].id
  }

  if (user && user.restaurants) {
    userForm.value.restaurant_name = user.restaurants[0].name || ''
    userForm.value.country = user.restaurants[0].country_alpha2_code || 'CH'
    userForm.value.language = user.language || appStore.language
    selectedLanguage.value = languagesList.value.find((language) => language.value === user.language) || languagesList.value[0]
  }
})
</script>

<template>
  <main class="mt-10">
    <div class="flex justify-end mb-4">
      <Dropdown class="w-40" :options="languagesList" optionLabel="label" v-model="selectedLanguage"
        @change="updateLanguage">
        <template #value="slotProps">
          {{ slotProps.value.label }}
        </template>
        <template #option="slotProps">
          {{ slotProps.option.label }}
        </template>
      </Dropdown>
    </div>
    <div class="flex flex-col items-center gap-6 my-6">
      <h1>{{ t('auth.registration.lastStep') }}</h1>
      <Avatar class="w-24 text-center mx-auto"></Avatar>
      <p class="font-bold">{{ t('common.welcome') }} {{ user?.first_name }} !</p>
      <p>{{ t('auth.registration.lastStepDescription') }}</p>
    </div>
    <div class="flex items-center gap-2 mt-4">
      <Checkbox v-model="isAChef" :binary="true" inputId="isAChef" />
      <label for="isAChef">{{ t('auth.registration.IHaveARestaurant') }}</label>
    </div>

    <!-- <form> -->
    <div class="my-8">
      <div v-if="isAChef" class="flex flex-col gap-4">

        <h1 class="text-lg">{{ t('auth.registration.restaurantData') }}</h1>
        <div class="flex flex-col gap-4">
          <div class="flex flex-col gap-2">
            <label for="restaurantName">{{ t('auth.user.form.label.restaurantName') }} *</label>
            <div>
              <InputText type="text" id="restaurantName" class="w-full" v-model="userForm.restaurant_name"
                :placeholder="t('auth.user.form.placeholder.restaurantName')"
                :invalid="hasError('restaurant_name') || !isRestaurantNameValid"
                @blur="touched.restaurant_name = true" />
            </div>
            <InlineMessage severity="error" v-if="hasError('restaurant_name') || !isRestaurantNameValid">
              {{ !isRestaurantNameValid ? t('auth.user.form.error.invalid_restaurant_name') :
                errors.restaurant_name[0] }}
            </InlineMessage>
          </div>
          <div class="flex flex-col gap-2">
            <label for="restaurantName">
              {{ t('profile.profile.restaurantTab.restaurantionType.description') }} *
            </label>
            <div class="flex flex-col gap-2">
              <div class="flex flex-row items-center" v-for="attribute in restaurationTypes" :key="attribute.id">
                <RadioButton v-model="userForm.restauration_type_id" :inputId="'radio-' + attribute.name"
                  :name="attribute.name" :value="attribute.id" />
                <label :for="'radio-' + attribute.name" class="ml-2">
                  {{ t(`profile.profile.restaurantTab.restaurantionType.${attribute.name}`) }}
                </label>
              </div>
            </div>
            <InlineMessage severity="error" v-if="hasError('restauration_type_id')">
              {{ errors.restauration_type_id[0] }}
            </InlineMessage>
          </div>
        </div>
      </div>

      <div class="flex flex-col gap-4 mt-8">
        <div class="flex flex-col gap-2">
          <label for="country">{{ t('auth.user.form.label.country') }} *</label>
          <CountrySelect v-model="userForm.country" :invalid="hasError('country') || !isCountryValid"
            @blur="touched.country = true" />
          <InlineMessage severity="error" v-if="hasError('country') || !isCountryValid">
            {{ !isCountryValid ? t('auth.user.form.error.invalid_country') : errors.country[0] }}
          </InlineMessage>
        </div>
        <div class="flex items-center gap-2 mt-8">
          <Checkbox v-model="acceptedCGU" :binary="true" inputId="acceptCGU" />
          <label for="acceptCGU">
            {{ t('auth.registration.acceptCGU') }}
            <InlineLink :destination="`/conditions-generales/${appStore.language}`" target="_blank">
              {{ t('auth.registration.readCGU') }}
            </InlineLink>
          </label>
        </div>
      </div>
    </div>
    <Button :label="t('common.validate')" :disabled="isFetching || !acceptedCGU" :loading="isFetching"
      class="w-full mt-4" size="large" type="submit" @click="submitForm" />
    <!-- </form> -->
  </main>
</template>
