<script setup lang="ts">
import type { Dish, plannedDishInfo } from '@/types/dish.js'
import BaseCard from '../BaseComponents/BaseCard.vue'
import { calculateDishPrice } from '@/utils/prices.js'
import ChefHatFull from '@/assets/icons/2States/ChefHatFull.vue'
import ChefHatEmpty from '@/assets/icons/2States/ChefHatEmpty.vue'
import IngredientsList from '../Menus/IngredientsList.vue'
import Button from 'primevue/button'
import ButtonMenu from '@/assets/icons/Buttons/ButtonMenu.vue'
import { useDishes } from '@/composables/useDishes.js'
import { ref, type Ref } from 'vue'
import { useI18n } from 'vue-i18n'
import type { MessageSchema } from '@/i18n/index.js'
import Menu from 'primevue/menu'
import HeartEmpty from '@/assets/icons/2States/HeartEmpty.vue'
import HeartFull from '@/assets/icons/2States/HeartFull.vue'
import { usePlanningStore } from '@/stores/planningStore'
import AddDishToPlanningDialog from '../Planning/AddDishToPlanningDialog.vue'
import AddTagDialog from '../Menus/AddTagDialog.vue'
import type { DishTag } from '@/types/api'
import { useDishesStore } from '@/stores/dishesStore'
import { useToast } from 'primevue/usetoast'
import { useConfirm } from 'primevue/useconfirm'
import ChefHatDiff from '@/assets/icons/Menus/ChefHatDiff.vue'
import { useUserStore } from '@/stores/useUserStore'
// import { useFetch } from '@/composables/useFetch'
// import { useEnvStore } from '@/stores/envStore'

const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })
const dishesStore = useDishesStore();
const toast = useToast()
const confirm = useConfirm()
const user = ref(useUserStore().user);
const props = defineProps<{
  dish: Dish
}>()
// const { fetchData } = useFetch()

const emit = defineEmits<{
  (event: 'handleDeletion', wasDeleted: boolean): void
  (event: 'handleDuplication', duplicationResponse: null | Dish): void
  (event: 'handleFavoriteChange', favoriteResponse: boolean | null): void
  (event: 'openPlanDishDialog', dish: Dish): void
  (event: 'cardClicked'): void
}>()

const { deleteDish, duplicateDish, toggleFavorite } = useDishes()

const showAddToPlanningDialog: Ref<boolean> = ref(false)

const openAddTagToDishDialog: Ref<boolean> = ref(false);

// const selectedTags: Ref<DishTag[]> = ref([]);

const planningStore = usePlanningStore()
const popupMenu = ref()
const popUpMenuItems = ref([
  {
    items: [
      {
        label: t('menuDishView.topMenu.addtoWeekMenu'),
        icon: 'pi pi-calendar',
        command: () =>
          addToWeekMenu()
      },
      {
        label: t('menuDishView.topMenu.duplicate'),
        icon: 'pi pi-clone',
        command: () =>
          duplicateDish(props.dish.id, 'copy').then((duplicationResponse) =>
            emit('handleDuplication', duplicationResponse)
          ),
      },
      {
        label: t('menuDishView.topMenu.delete'),
        icon: 'pi pi-trash',
        command: (event) => {
          confirm.require({
            target: event.currentTarget,
            message: t('menuDishView.deleteDish.label'),
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: t('menuDishView.deleteDish.cancel'),
            rejectLabel: t('menuDishView.deleteDish.confirm'),
            accept: () => {
            },
            reject: () => {
              deleteDish(props.dish.id).then((wasDeleted) => emit('handleDeletion', wasDeleted))
            },
          })
        },
      },
      {
        label: t('menuDishView.topMenu.addToACategory'),
        icon: 'pi pi-tag',
        command: () => addTag(),
      }, ...(user.value?.role === 'admin' ? [{
        label: props.dish.is_template ? t('dish.removeTemplate') : t('dish.setTemplate'),
        icon: props.dish.is_template ? 'pi pi-star-fill' : 'pi pi-star',
        command: () => {
          useDishes().setDishTemplate(props.dish.id, toast)
        }
      }] : []),

      // {
      //   label: t('menuDishView.topMenu.share'),
      //   icon: 'pi pi-share-alt',
      // },
    ],
  },
])

async function addTag() {
  if (!props.dish) return;

  // try {
  //   // Check if dish already has tags
  //   if (!props.dish.tags) {
  //     // Fetch tags from API
  //     const response = await fetchData(`${useEnvStore().apiUrl}/dishes/${props.dish.id}/tags`);
  //     if (!response.data) {
  //       throw new Error('Failed to fetch tags');
  //     }
  //     selectedTags.value = response.data;
  //     props.dish.tags = response.data;
  //   } else {
  //     selectedTags.value = props.dish.tags;
  //   }

  //   openAddTagToDishDialog.value = true;
  // } catch (error) {
  //   console.error('Error fetching tags:', error);
  //   toast.add({
  //     severity: 'error',
  //     summary: t('common.error'),
  //     detail: t('menuDishView.tag.errorFetchingTags'),
  //     life: 3000,
  //   });
  // }
  openAddTagToDishDialog.value = true;
}

async function updateTagsOnDish(newTagSelection: DishTag[]) {
  if (!props.dish) return

  // Vérifiez s'il y a une différence entre la sélection actuelle et la nouvelle sélection
  const existingTags = props.dish.tags ?? []

  const isDifferent =
    existingTags.length !== newTagSelection.length ||
    existingTags.some(existingTag => !newTagSelection.some(newTag => newTag.id === existingTag.id))

  // If there's no change, don't do anything.
  if (!isDifferent) {
    // console.log("Pas de changement détecté dans les tags. Aucune action nécessaire.")
    return
  }

  try {
    const dishId = props.dish.id;
    const tagsId = newTagSelection.map(tag => tag.id); // envoyer les IDs des tags sélectionnés

    dishesStore.updateDishTags(dishId, tagsId, toast);

  } catch (e) {
    console.error('Erreur lors de la mise à jour des tags:', e)
    toast.add({
      severity: 'error',
      summary: t('common.error'),
      detail: t('menuDishView.tag.errorUpdatingTags'),
      life: 3000,
    })
  }
}


async function addToWeekMenu() {
  if (!props.dish) return

  planningStore.dishToPlan = {
    clipboardDish: props.dish,
    selectedPlannedMenuIds: [],
    dishType: 'main'
  };

  showAddToPlanningDialog.value = true;
}


function togglePopUpMenu(event: MouseEvent) {
  popupMenu.value.toggle(event)
}
</script>

<template>
  <Menu ref="popupMenu" :model="popUpMenuItems" :popup="true" :pt="{ submenuHeader: { class: '!p-0' } }"
    :pt-options="{ mergeProps: true }" />
  <div class="py-3 mb-4">
    <BaseCard @click="$emit('cardClicked')">
      <div class="flex flex-row items-start justify-between gap-4">
        <div class="mb-4">
          <h3 class="line-clamp-2">{{ dish.name }}</h3>
          <ul class="flex flex-col">
            <li>
              <p class="inline text-xs">{{ t('history.creationDate') }}
                {{
                new Date(dish.created_at).toLocaleString('fr-CH', {
                dateStyle: 'short',
                })
                }}</p>
            </li>
            <!-- <li>
                <h4 class="block mb-1">Ingrédients</h4>
                <IngredientsList
                gradientFrom="white"
                list-height="full"
                :ingredients="dish.ingredients"
                />
              </li> -->
          </ul>
          <p class="text-xs italic">
            <!-- {{ dish.created_using }} -->
            {{ t(`history.${dish.created_using}GeneratedDish`) }}
          </p>
        </div>
        <div v-if="!planningStore.choosingDishToPlan" class="flex flex-row gap-2" @click.stop>
          <Button link rounded :pt="{ root: { class: '!p-1.5 !w-8 !h-8 rotate-90' } }"
            :pt-options="{ mergeProps: true }" @click="togglePopUpMenu">
            <template #icon>
              <ButtonMenu />
            </template>
          </Button>
        </div>
      </div>

      <ul v-if="dish.isPlanned" class="flex flex-col  mb-4  ">
        <li>
          <h4 class="inline text-primary-950">{{ t('history.plannedOn') }}</h4>
        </li>
        <li v-for="(plannedDish, index) in dish.isPlanned" :key="index" class="flex flex-row gap-2 text-xs">
          <p class="truncate">{{ new Date(plannedDish.date).toLocaleString('fr-CH', {
            dateStyle: 'short',
            }) }} - {{ plannedDish.menu_name }}</p>
        </li>
      </ul>
      <div class="flex flex-row items-center justify-between ">
        <div class="flex flex-row items-center gap-3">
          <div v-if="!planningStore.choosingDishToPlan" class="flex flex-row gap-2" @click.stop>
            <Button link rounded :pt="{ root: { class: '!p-1.5 !w-8 !h-8' } }" :pt-options="{ mergeProps: true }"
              @click="
                toggleFavorite(props.dish.id).then((favoriteResponse) =>
                  emit('handleFavoriteChange', favoriteResponse)
                )
                ">
              <template #icon>
                <HeartFull v-if="dish.is_favorite" />
                <HeartEmpty v-else />
              </template>
            </Button>
          </div>

          <div class="flex flex-row items-center w-16 h-6 gap-3 text-primary-950">
            <ChefHatDiff :level="dish.difficulty" />
            <!-- <ChefHatEmpty v-for="i in 4 - dish.difficulty" :key="i" /> -->
          </div>
        </div>
        <div class="px-3 py-1 text-xs font-titles font-semibold text-primary-950">
          <div>
            ~ {{ dish?.country?.currency_symbol }} {{ calculateDishPrice(dish) }} / {{ t('common.personShort') }}
            <!-- <h3 class="text-sm">
            </h3> -->
          </div>
        </div>
      </div>
    </BaseCard>
  </div>
  <AddDishToPlanningDialog v-model:visible="showAddToPlanningDialog" :dish="dish"
    @close-sidebar="showAddToPlanningDialog = false" />
  <AddTagDialog v-model:visible="openAddTagToDishDialog" @update-selection="updateTagsOnDish"
    :selected-tags="props.dish.tags ?? []" v-if="openAddTagToDishDialog" />
</template>

<style scoped></style>
