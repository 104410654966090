<script setup lang="ts">
import { useFetch } from '@/composables/useFetch.js'
import { useEnvStore } from '@/stores/envStore.js'
import type { DishHistoryResponse } from '@/types/api.js'
import { nextTick, onMounted, ref, watch, watchEffect, type Ref } from 'vue'
import AutoComplete from 'primevue/autocomplete'
import type { MessageSchema } from '@/i18n'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import type { DishHistoryState } from '@/types/history.js'
import { useToast } from 'primevue/usetoast'
import { useProfileDishLists } from '@/composables/useProfileDishLists.js'

const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })

const router = useRouter()
const toast = useToast()

const { fetchData } = useFetch()

const apiUrl = useEnvStore().apiUrl

const searchQuery = ref('')
const isSearching = ref(false)

const createdUsing: Ref<string[]> = ref(['ocr', 'ia'])

const isFetchingList = ref(false)

const nextPageUrl = ref(`${apiUrl}/dishes`)
const loadedDishes = ref(0)
const totalDishes = ref(0)

const { dishes, handleDuplication, handleDeletion, handleFavoriteChange } =
  useProfileDishLists(toast)

function handleCardClick(id: number) {
  saveState()
  router.push(`/menu/dishes/${id}`)
}

watchEffect(() => console.log(dishes.value.length))

async function getDishes() {
  // This var is needed because the isFetching var from composable
  // Makes a conflict between the getDishes and the searchDishes methods
  isFetchingList.value = true

  const { data, error } = await fetchData(nextPageUrl.value, 'GET')
  if (error) {
    console.log('error', error)
  } else {
    const response = data as DishHistoryResponse
    console.log('response', response)
    dishes.value = [...dishes.value, ...response.data]
    loadedDishes.value = response.to
    totalDishes.value = response.total
    nextPageUrl.value = response.next_page_url ?? ''
  }

  isFetchingList.value = false
}

// This method is only triggered when we type in the serach bar or when the checkboxes are clicked.
// The pagination is the handled by getDishes
async function filterDishes() {
  // isSearching is needed to display search icon when the method is triggered with the watcher
  isSearching.value = true

  // const searchUrl = `${apiUrl}/dishes/search?name=${encodeURIComponent(searchQuery.value)}`
  const searchUrl = `${apiUrl}/dishes/search?${new URLSearchParams({
    name: searchQuery.value,
    created_using: createdUsing.value.join(','),
  })}`

  const { data, error } = await fetchData(searchUrl, 'GET')

  if (error) {
    console.log('error', error)
  } else {
    const response = data as DishHistoryResponse
    dishes.value = response.data
    loadedDishes.value = response.to
    totalDishes.value = response.total
    nextPageUrl.value = response.next_page_url ?? ''
  }
  isSearching.value = false
}

const scrollPosition = ref(0)

function saveState() {
  const stateToSave: DishHistoryState = {
    dishes: dishes.value,
    loadedDishes: loadedDishes.value,
    totalDishes: totalDishes.value,
    nextPageUrl: nextPageUrl.value,
    scrollPosition: window.scrollY,
    searchQuery: searchQuery.value,
    createdUsing: createdUsing.value,
  }
  sessionStorage.setItem('historyState', JSON.stringify(stateToSave))
}

function restoreState() {
  dishes.value = []
  const savedState = sessionStorage.getItem('historyState')
  if (savedState) {
    const state: DishHistoryState = JSON.parse(savedState)
    dishes.value = state.dishes
    console.log(dishes.value)
    loadedDishes.value = state.loadedDishes
    totalDishes.value = state.totalDishes
    nextPageUrl.value = state.nextPageUrl
    scrollPosition.value = state.scrollPosition
    searchQuery.value = state.searchQuery
    createdUsing.value = state.createdUsing || [] // Provide default empty array if not found
    console.log(scrollPosition.value)
    nextTick(() => {
      window.scrollTo(0, scrollPosition.value)
    })
    sessionStorage.removeItem('historyState')
  } else {
    getDishes()
  }
}

watch(searchQuery, () => {
  if (searchQuery.value === '') {
    filterDishes()
  }
})

onMounted(() => {
  restoreState()
})
</script>

<template>
  <main>
    <AutoComplete v-model="searchQuery" :suggestions="dishes" :loading="isSearching" @complete="filterDishes"
      panel-class="hidden" :placeholder="t('common.searchPlaceHolder')" />
  </main>
</template>
