<script setup lang="ts">
import type { MessageSchema } from '@/i18n';
import { useI18n } from 'vue-i18n';

const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })
const props = defineProps<{
  title: string
  subtitle: string
  comment?: string
  destination?: string
}>()
</script>

<template>
  <div class="flex rounded-lg shadow-md bg-[#fafafa] overflow-hidden">
    <!-- Blue Strip -->
    <div class="bg-primary-500 w-2"></div>
    <div class="py-2 px-2 flex justify-between w-full items-center">
      <!-- Content -->
      <div>
        <p class="text-primary-950 text-sm font-bold">
          {{ props.title }}
        </p>
        <p class="text-primary-950 text-xs">{{ props.subtitle }}</p>
        <p class="text-primary-950 text-xs" v-if="props.comment">{{ props.comment }}</p>
      </div>
      <!-- <div class="text-left min-w-20">
            <p class="text-primary-950 text-xs">Total</p>
            <p class="text-primary-950 text-sm font-bold">{{ props.price }}</p>
          </div> -->
    </div>
  </div>
</template>

<style scoped></style>
