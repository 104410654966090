import AvatarLogo from '@/assets/images/avatar-rounded.webp'
import OpenSansBold from '@/assets/fonts/open-sans/OpenSans-Bold.ttf'
import OpenSansRegular from '@/assets/fonts/open-sans/OpenSans-Regular.ttf'
import OpenSansLight from '@/assets/fonts/open-sans/OpenSans-Light.ttf'
import type { Supplier, User } from '@/types/api'
import type { Ingredient } from '@/types/dish'
import jsPDF from 'jspdf'
import { useUserStore } from '@/stores/useUserStore'
import type { TableRow } from '@/types/order'


export const useOrder = () => {
  const convertWebpToPng = (imgSrc: string): Promise<{ dataUrl: string; aspectRatio: number }> => {
    return new Promise((resolve) => {
      const img = new Image()
      img.onload = () => {
        const canvas = document.createElement('canvas')
        canvas.width = img.width
        canvas.height = img.height
        const ctx = canvas.getContext('2d')
        if (ctx) {
          ctx.drawImage(img, 0, 0)
        }
        resolve({
          dataUrl: canvas.toDataURL('image/png'),
          aspectRatio: img.width / img.height
        })
      }
      img.src = imgSrc
    })
  }

  function formatDate(date: Date): string {
    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const year = date.getFullYear()
    return `${day}.${month}.${year}`
  }

  // New helper function
  function renderIngredientsList(
    doc: jsPDF,
    ingredients: TableRow[],
    startY: number,
    margin: number,
    lineHeight: number
  ): number {
    let y = startY;
    ingredients.forEach(ingredient => {
      doc.circle(margin + 2, y - 1.5, 0.8, 'F')
      const quantityUnit = [ingredient.data.quantity, ingredient.data.unit]
        .filter(Boolean)
        .join(' ');
      const text = quantityUnit
        ? `${quantityUnit} - ${ingredient.data.name}`
        : ingredient.data.name;
      doc.text(text, margin + 5, y)
      y += lineHeight
    })
    return y;
  }

  async function generatePDF(
    t: Function,
    ingredientsToOrder: TableRow[],
    supplierList: Supplier[],
    selectedSupplierUuid: string | null,
    wantedDate: Date,
    customMessage: string | null
  ) {
    // Convert both logos
    const user = useUserStore().user as User
    const { dataUrl: transparentPng, aspectRatio: avatarAspectRatio } = await convertWebpToPng(AvatarLogo);
    const restaurantLogo = user?.restaurants[0].logo;
    let restaurantLogoData;
    if (restaurantLogo) {
      restaurantLogoData = await convertWebpToPng(restaurantLogo);
    }

    const doc = new jsPDF({
      orientation: 'portrait',
      unit: 'mm',
      format: 'a4'
    })

    // Add fonts
    doc.addFont(OpenSansBold, 'OpenSans-Bold', 'bold')
    doc.addFont(OpenSansRegular, 'OpenSans-Regular', 'regular')
    doc.addFont(OpenSansLight, 'OpenSans-Light', 'light')

    // Page setup
    const margin = 20
    const pageWidth = 210
    const lineHeight = 8
    const lineHeightTight = 6
    let y = margin + lineHeight * 2

    // TITLE //
    doc.setFont('OpenSans-Bold', 'bold')
    doc.setFontSize(16)
    doc.text(`${t('orders.orderForm.file.orderOfDate', { date: formatDate(new Date()) })} - ${user?.restaurants[0].name}`, pageWidth / 2, margin, { align: 'center' })

    // CONTENT //
    doc.setFont('OpenSans-Regular', 'regular')
    doc.setFontSize(12)

    // Supplier info
    const supplier = supplierList.find(s => s.uuid === selectedSupplierUuid)
    doc.text(`${t('orders.orderForm.file.provider')}: ${supplier?.name || '-'}`, margin, y)
    y += lineHeight
    doc.text(`${t('orders.orderForm.file.deliveryDateWanted')}: ${formatDate(wantedDate)}`, margin, y)
    y += lineHeight

    // Ingredients list
    doc.text(t('orders.orderForm.file.ingredientsList'), margin, y)
    y += lineHeight
    doc.setFont('OpenSans-Light', 'light')

    const ingredientsPerPage = 15;
    const needsPagination = ingredientsToOrder.length >= 18;
    const totalPages = needsPagination ? 2 : 1;

    if (needsPagination) {
      // First page ingredients
      y = renderIngredientsList(doc, ingredientsToOrder.slice(0, ingredientsPerPage), y, margin, lineHeight);
      addFooter(doc, margin, transparentPng, avatarAspectRatio, t, 1, totalPages)

      // Add new page
      doc.addPage()
      y = margin + lineHeight * 2

      // Reset font settings for new page
      doc.setFont('OpenSans-Regular', 'regular')
      doc.setFontSize(12)
      doc.text(t('orders.orderForm.file.ingredientsList'), margin, y)
      y += lineHeight
      doc.setFont('OpenSans-Light', 'light')

      // Second page ingredients
      y = renderIngredientsList(doc, ingredientsToOrder.slice(ingredientsPerPage), y, margin, lineHeight);
      addFooter(doc, margin, transparentPng, avatarAspectRatio, t, 2, totalPages)
    } else {
      // Single page - all ingredients
      y = renderIngredientsList(doc, ingredientsToOrder, y, margin, lineHeight);
      addFooter(doc, margin, transparentPng, avatarAspectRatio, t, 1, 1)
    }

    // Custom message
    if (customMessage) {
      y += lineHeight
      doc.setFont('OpenSans-Regular', 'regular')
      doc.setFontSize(12)
      doc.text(t('orders.orderForm.file.customMessage'), margin, y)
      y += lineHeight
      doc.setFont('OpenSans-Light', 'light')
      const messageLines = doc.splitTextToSize(customMessage, pageWidth - (margin * 2))
      messageLines.forEach((line: string) => {
        doc.text(line, margin, y)
        y += lineHeight
      })
    }

    // Restaurant info
    doc.setFont('OpenSans-Regular', 'regular')
    doc.setFontSize(12)
    y += lineHeight
    doc.text(`${t('orders.orderForm.file.restaurant')}`, margin, y)

    // Restaurant Logo
    if (restaurantLogoData) {
      const maxLogoHeight = 30;
      const maxLogoWidth = 60;
      let logoHeight = maxLogoHeight;
      let logoWidth = logoHeight * restaurantLogoData.aspectRatio;

      if (logoWidth > maxLogoWidth) {
        logoWidth = maxLogoWidth;
        logoHeight = logoWidth / restaurantLogoData.aspectRatio;
      }

      const logoX = pageWidth - margin - logoWidth;
      const logoY = y;

      doc.addImage(restaurantLogoData.dataUrl, 'PNG', logoX, logoY, logoWidth, logoHeight);
      // y += logoHeight + lineHeight;
    }

    y += lineHeightTight

    // Content
    doc.setFont('OpenSans-Light', 'light')
    doc.text(`${user?.restaurants[0].name}`, margin, y)
    y += lineHeightTight
    if (user?.restaurants[0].phone_number) {
      doc.text(`${user?.restaurants[0].phone_number}`, margin, y)
      y += lineHeightTight
    }


    y += lineHeight // Extra space after restaurant info

    // Footer section
    const pageHeight = 297
    const footerY = pageHeight - (margin / 2)
    const footerHeight = 15
    const footerCenterY = footerY - (footerHeight / 2)

    // Footer logo dimensions
    const logoHeight = 12
    const maxLogoWidth = 24
    let logoWidth = logoHeight * avatarAspectRatio
    if (logoWidth > maxLogoWidth) {
      logoWidth = maxLogoWidth
    }

    // Draw avatar logo on the left
    const logoY = footerCenterY - (logoHeight / 2)
    doc.addImage(transparentPng, 'PNG', margin, logoY, logoWidth, logoHeight)

    // Calculate text widths for left side
    doc.setFont('OpenSans-Light', 'light')
    doc.setFontSize(7)
    const poweredByText = 'Powered by '
    const poweredByWidth = doc.getTextWidth(poweredByText)

    doc.setFont('OpenSans-Bold', 'bold')
    const gastronomiaText = 'GastronomIA'

    // Draw "Powered by GastronomIA" text vertically centered
    const textY = footerCenterY + 2
    doc.setTextColor(23, 52, 71)
    doc.setFont('OpenSans-Light', 'light')
    doc.text(poweredByText, margin + logoWidth + 2, textY)
    doc.setFont('OpenSans-Bold', 'bold')
    doc.text(gastronomiaText, margin + logoWidth + 2 + poweredByWidth, textY)

    // Reset text color for the rest of the document
    doc.setTextColor(0, 0, 0)

    // Save the PDF with new filename format. For the pdf to have unique name, add the currentDate with hours minutes
    const date = new Date().toISOString().replace(/[-:.TZ]/g, '');
    const supplierName = supplier?.name ? `-${supplier?.name}-` : '';
    doc.save(`${t('orders.orderForm.file.order')}${supplierName}${date}.pdf`)
  }

  // Helper function to add footer
  function addFooter(
    doc: jsPDF,
    margin: number,
    logoImage: string,
    avatarAspectRatio: number,
    t: Function,
    currentPage: number,
    totalPages: number
  ) {
    const pageWidth = 210
    const pageHeight = 297
    const footerY = pageHeight - (margin / 2)
    const footerHeight = 15
    const footerCenterY = footerY - (footerHeight / 2)

    // Footer logo dimensions
    const logoHeight = 12
    const maxLogoWidth = 24
    let logoWidth = logoHeight * avatarAspectRatio
    if (logoWidth > maxLogoWidth) {
      logoWidth = maxLogoWidth
    }

    // Draw avatar logo on the left
    const logoY = footerCenterY - (logoHeight / 2)
    doc.addImage(logoImage, 'PNG', margin, logoY, logoWidth, logoHeight)

    // Calculate text widths for left side
    doc.setFont('OpenSans-Light', 'light')
    doc.setFontSize(7)
    const poweredByText = 'Powered by '
    const poweredByWidth = doc.getTextWidth(poweredByText)

    // Draw "Powered by GastronomIA" text vertically centered
    const textY = footerCenterY + 2
    doc.setTextColor(23, 52, 71)
    doc.setFont('OpenSans-Light', 'light')
    doc.text(poweredByText, margin + logoWidth + 2, textY)
    doc.setFont('OpenSans-Bold', 'bold')
    doc.text('GastronomIA', margin + logoWidth + 2 + poweredByWidth, textY)

    // Add page numbers centered
    doc.setFont('OpenSans-Regular', 'regular')
    doc.setFontSize(10)
    doc.setTextColor(23, 52, 71)
    const pageText = t('orders.orderForm.file.pageOnPage', { page: currentPage, totalPage: totalPages })
    doc.text(pageText, pageWidth - margin, footerCenterY + 2, { align: 'right' })

    // Reset text color
    doc.setTextColor(0, 0, 0)
  }

  return {
    generatePDF,
    formatDate
  }
}