<script setup lang="ts">
import LoadingView from '../LoadingView.vue'
import { useFetch } from '@/composables/useFetch.js'
import { useEnvStore } from '@/stores/envStore.js'
import type { DishHistoryResponse } from '@/types/api.js'
import Button from 'primevue/button'
import { nextTick, onMounted, ref, watch, type Ref } from 'vue'
import AutoComplete from 'primevue/autocomplete'
import Checkbox from 'primevue/checkbox'
import type { MessageSchema } from '@/i18n'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import type { Dish } from '@/types/dish.js'
import type { DishHistoryState } from '@/types/history.js'
import { useToast } from 'primevue/usetoast'
import { useProfileDishLists } from '@/composables/useProfileDishLists.js'
import HistoryCard from '@/components/Profile/HistoryCard.vue'
import { usePlanningStore } from '@/stores/planningStore'

const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })

const router = useRouter()
const toast = useToast()

const { fetchData } = useFetch()

const apiUrl = useEnvStore().apiUrl

const searchQuery = ref('')
const isSearching = ref(false)

const createdUsing: Ref<string[]> = ref(['ocr', 'ia'])

const isFetchingList = ref(false)

const nextPageUrl = ref(`${apiUrl}/dishes`)
const loadedDishes = ref(0)
const totalDishes = ref(0)
const planningStore = usePlanningStore()


const { dishes, handleDuplication, handleDeletion, handleFavoriteChange } =
  useProfileDishLists(toast)

function handleCardClick(id: number) {
  if (planningStore.choosingDishToPlan) {
    emit('addDishToPlan', dishes.value.find((dish) => dish.id === id) as Dish)
    return
  } else {
    saveState()
    router.push(`/menu/dishes/${id}`)
  }
}

// When the users wants to add a dish from the planning.
const emit = defineEmits<{
  (e: 'addDishToPlan', dish: Dish): void
}>()

async function getDishes() {
  // This var is needed because the isFetching var from composable
  // Makes a conflict between the getDishes and the searchDishes methods
  isFetchingList.value = true

  const { data, error } = await fetchData(nextPageUrl.value, 'GET')
  if (error) {
    console.log('error', error)
  } else {
    const response = data as DishHistoryResponse
    console.log('response', response)
    dishes.value = [...dishes.value, ...response.data]
    loadedDishes.value = response.to
    totalDishes.value = response.total
    nextPageUrl.value = response.next_page_url ?? ''
  }

  isFetchingList.value = false
}

// This method is only triggered when we type in the serach bar or when the checkboxes are clicked.
// The pagination is the handled by getDishes
async function filterDishes() {
  // isSearching is needed to display search icon when the method is triggered with the watcher
  isSearching.value = true

  // const searchUrl = `${apiUrl}/dishes/search?name=${encodeURIComponent(searchQuery.value)}`
  const searchUrl = `${apiUrl}/dishes/search?${new URLSearchParams({
    name: searchQuery.value,
    created_using: createdUsing.value.join(','),
    favorites: 'true',
  })}`

  const { data, error } = await fetchData(searchUrl, 'GET')

  if (error) {
    console.log('error', error)
  } else {
    const response = data as DishHistoryResponse
    dishes.value = response.data
    loadedDishes.value = response.to
    totalDishes.value = response.total
    nextPageUrl.value = response.next_page_url ?? ''
  }
  isSearching.value = false
}

const scrollPosition = ref(0)

function saveState() {
  const stateToSave: DishHistoryState = {
    dishes: dishes.value,
    loadedDishes: loadedDishes.value,
    totalDishes: totalDishes.value,
    nextPageUrl: nextPageUrl.value,
    scrollPosition: window.scrollY,
    searchQuery: searchQuery.value,
    createdUsing: createdUsing.value,
  }
  sessionStorage.setItem('favoriteState', JSON.stringify(stateToSave))
}

function restoreState() {
  dishes.value = []
  const savedState = sessionStorage.getItem('favoriteState')
  if (savedState) {
    const state: DishHistoryState = JSON.parse(savedState)
    dishes.value = state.dishes
    loadedDishes.value = state.loadedDishes
    totalDishes.value = state.totalDishes
    nextPageUrl.value = state.nextPageUrl
    scrollPosition.value = state.scrollPosition
    searchQuery.value = state.searchQuery
    createdUsing.value = state.createdUsing || [] // Provide default empty array if not found
    console.log(scrollPosition.value)
    nextTick(() => {
      window.scrollTo(0, scrollPosition.value)
    })
    sessionStorage.removeItem('favoriteState')
  } else {
    filterDishes()
  }
}

watch(searchQuery, () => {
  if (searchQuery.value === '') {
    filterDishes()
  }
})

onMounted(() => {
  restoreState()
})
</script>

<template>
  <main>
    <div class="flex flex-col gap-2 mb-6">
      <AutoComplete
        v-model="searchQuery"
        :suggestions="dishes"
        :loading="isSearching"
        @complete="filterDishes"
        panel-class="hidden"
        :placeholder="t('common.searchPlaceHolder')"
      />
      <div class="flex flex-row gap-6">
        <div class="flex flex-row items-center gap-2">
          <Checkbox
            v-model="createdUsing"
            inputId="ia"
            name="ia"
            value="ia"
            @change="filterDishes"
          />
          <label for="ia">{{ t('profile.dishLists.iaGenerated') }}</label>
        </div>
        <div class="flex flex-row items-center gap-2">
          <Checkbox
            v-model="createdUsing"
            inputId="ocr"
            name="ocr"
            value="ocr"
            @change="filterDishes"
          />
          <label for="ocr">{{ t('profile.dishLists.ocrGenerated') }}</label>
        </div>
      </div>
    </div>
    <HistoryCard
      v-for="dish in dishes"
      :key="dish.id"
      :dish="dish"
      @card-clicked="handleCardClick(dish.id)"
      @handle-deletion="(wasDeleted: boolean) => handleDeletion(wasDeleted, dish.id)"
      @handle-duplication="
        (duplicationResponse: null | Dish) => handleDuplication(duplicationResponse, dish.id)
      "
      @handle-favorite-change="
        (favoriteResponse: boolean | null) => handleFavoriteChange(favoriteResponse, dish.id, true)
      "
    />
    <Button
      v-if="loadedDishes < totalDishes"
      @click="getDishes()"
      :loading="isFetchingList && dishes.length !== 0"
      :label="t('common.seeMore')"
    />
    <LoadingView v-if="isFetchingList && dishes.length === 0" />
  </main>
</template>
