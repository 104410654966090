<script setup lang="ts">
import { useDishesStore } from '@/stores/dishesStore'
import { computed, nextTick, onMounted, onUnmounted, ref, watch, type ComputedRef, type Ref } from 'vue'
import type { Dish } from '@/types/dish'
import { useI18n } from 'vue-i18n'
import type { MessageSchema } from '@/i18n'
import { useFetch } from '@/composables/useFetch'
import { useEnvStore } from '@/stores/envStore'
import { useToast } from 'primevue/usetoast'
import { useRoute, useRouter } from 'vue-router'
import { usePlanningStore } from '@/stores/planningStore'
import PlannedMenuLine from '@/components/Planning/PlannedMenuLine.vue'
import Button from 'primevue/button'
import ButtonShare from '@/assets/icons/Buttons/ButtonShare.vue'
import InputText from 'primevue/inputtext'
import { debounce } from '@/utils/functions'
import html2canvas from 'html2canvas-pro'
import Dialog from 'primevue/dialog'
import ShareCard from '@/assets/icons/Planning/ShareCard.vue'
import DishImage from '@/components/Menus/DishImage.vue'
import type { MenuCourse } from '@/types/planning'
import Menu from 'primevue/menu'
import ButtonMenu from '@/assets/icons/Buttons/ButtonMenu.vue'
import { useConfirm } from 'primevue/useconfirm'
import InputNumber from '@/components/BaseComponents/InputNumber.vue'
import NutritionalValuesCard from '@/components/Menus/MenuDishViewComponents/NutritionalValuesCard.vue'
import { useUserStore } from '@/stores/useUserStore'
import ImagePlaceholderIcon from '@/assets/icons/Profile/ImagePlaceholderIcon.vue'

const { t, locale } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })

const route = useRoute()
const router = useRouter()
const toast = useToast()
const envStore = useEnvStore()
const dishesStore = useDishesStore()
const dish = ref<Dish | null>(dishesStore.selectedDish)
dish.value = dishesStore.selectedDish
const { fetchData } = useFetch()
const confirmDialog = useConfirm()

const planningStore = usePlanningStore()
const { user } = useUserStore()
const shareModalVisible = ref(false)
const isImageModalVisible = ref(false)
const dishImageRef: Ref<InstanceType<typeof DishImage> | null> = ref(null)
const popupMenu = ref()
const popupShareMenu = ref()
const mainDishImageSrc = ref('')
const dessertImageSrc = ref('')
const starterImageSrc = ref('')  // Add this line

const isTitleTooLong = ref(false)
const MAX_TITLE_LENGTH = 20
const MAX_UPPERCASE_LENGTH = 14

const popUpMenuItems = ref([
  {
    items: [
      {
        label: t('planning.plannedMenu.delete.buttonLabel'),
        icon: 'pi pi-trash',
        command: () => confirmDeletePlannedMenu(),
      },
      // {
      //   label: t('menuDishView.topMenu.duplicate'),
      //   icon: 'pi pi-clone',
      //   command: () =>
      //   console.log("Duplicate"),
      //     // duplicateDish(props.dish.id).then((duplicationResponse) =>
      //     //   emit('handleDuplication', duplicationResponse)
      //     // ),
      // },
      // {
      //   label: t('menuDishView.topMenu.share'),
      //   icon: 'pi pi-share-alt',
      // },
    ],
  },
])

const shareMenuItems = computed(() => {
  const items: { label: string; icon: string; command: () => Promise<void> }[] = []

  // Check if starter exists and has image
  const starterCourse = sortedAndFilledCourses.value[0]
  if (starterCourse?.dish?.selected_image && starterCourse.dish.selected_image !== 'none') {
    items.push({
      label: t('planning.plannedMenu.share.storyStarter'),
      icon: 'pi pi-image',
      command: () => shareMenu('storyStarter'),
    })
  }

  if (mainDish.value && mainDish.value.imageUrl && mainDish.value.selected_image !== 'none') {
    // Add main dish option always since it's handled separately
    items.push({
      label: t('planning.plannedMenu.share.storyMainDish'),
      icon: 'pi pi-image',
      command: () => shareMenu('storyMainDish'),
    })
    // Add full menu option always
    items.push({
      label: t('planning.plannedMenu.share.storyFull'),
      icon: 'pi pi-image',
      command: () => shareMenu('storyFull'),
    })
  }

  // Check if dessert exists and has image
  const dessertCourse = sortedAndFilledCourses.value[2]
  if (dessertCourse?.dish?.selected_image && dessertCourse.dish.selected_image !== 'none') {
    items.push({
      label: t('planning.plannedMenu.share.storyDessert'),
      icon: 'pi pi-image',
      command: () => shareMenu('storyDessert'),
    })
  }


  return items
})

const plannedMenuTitle = ref(planningStore.selectedPlannedMenu?.name || '')

const plannedMenuPrice = ref(planningStore.selectedPlannedMenu?.price || 0);

const formattedPrice: ComputedRef<string> = computed(() => {
  const price = plannedMenuPrice.value;
  const symbol = user?.restaurants[0].currency_symbol || 'chf';
  // Ensure price is a number and convert it if necessary
  const numericPrice = typeof price === 'string' ? parseFloat(price) : Number(price);
  
  // Check if the conversion resulted in a valid number
  if (isNaN(numericPrice)) {
    return ``;
  }
  
  return `${symbol} ${numericPrice.toFixed(2)}`;
});


watch(
  () => planningStore.selectedPlannedMenu?.name,
  (newName) => {
    plannedMenuTitle.value = newName || ''
  }
)

watch(
  () => planningStore.selectedPlannedMenu?.price,
  (newPrice) => {
    plannedMenuPrice.value = newPrice || 0;
  }
)
const temporaryMenuCourses = ref<MenuCourse[]>([])

// When dishToPlanClipboard is not null, prepare to add the dish to the temporary list
watch(
  () => planningStore.dishToPlan?.clipboardDish,
  (newDish) => {
    if (newDish) {
      // Add the dish to the first available course (e.g., entree if it's empty)
      const emptyCourse = temporaryMenuCourses.value.find((course) => !course.dish)
      if (emptyCourse) {
        emptyCourse.dish = newDish // Assign the dish to the empty course
      } else {
        // Or push it into a new course
        temporaryMenuCourses.value.push({
          id: -1, // Temporary ID
          name: newDish.name,
          order: 2, // Example: main dish
          dish: newDish,
          planned_menu_id: planningStore.selectedPlannedMenu?.id || -1,
        })
      }
    }
  }
)

const updatePlannedMenuTitle = async () => {
  if (planningStore.selectedPlannedMenu) {
    planningStore.selectedPlannedMenu.name = plannedMenuTitle.value
    await planningStore.updatePlannedMenu(planningStore.selectedPlannedMenu)
  }
}

const updatePlannedMenuPrice = async () => {
  if (planningStore.selectedPlannedMenu) {
    // const parsedPrice = parseFloat(tempPrice.value);
    // Ensure the price is not negative and is a valid number
    // const validPrice = !isNaN(parsedPrice) ? Math.max(0, parsedPrice) : 0;
    // plannedMenuPrice.value = validPrice;
    planningStore.selectedPlannedMenu.price = plannedMenuPrice.value;
    await planningStore.updatePlannedMenu(planningStore.selectedPlannedMenu);
  }
}


// Create a debounced version of the updatePlannedMenuTitle function
// const debouncedUpdatePlannedMenuTitle = debounce(updatePlannedMenuTitle, 1000)
// const debouncedUpdatePlannedMenuPrice = debounce(updatePlannedMenuPrice, 750);

// mounted
onMounted(async () => {
  // console.log("mounted PlannedMenuView");
  if (
    !planningStore.selectedPlannedMenu ||
    (planningStore.selectedPlannedMenu.id.toString() !== route.params.id &&
      !planningStore.dishToPlan)
  ) {
    // console.log("no selectedPlannedMenu, get the plannedMenu from route");
    planningStore.selectPlannedMenu(parseInt(route.params.id.toString()))
  }
})

onUnmounted(() => {
  // console.log("unMount Planned Menu, set selectedPlannedMenu to null")
  planningStore.selectedPlannedMenu = null
})

const formattedDate = computed(() => {
  if (!planningStore.selectedPlannedMenu?.date) return ''

  const date = new Date(planningStore.selectedPlannedMenu.date)
  const options: Intl.DateTimeFormatOptions = {
    weekday: 'long', // 'long' format for the day
    month: 'long', // 'long format of month'
    day: '2-digit', // two-digit day
    year: 'numeric', // four-digit year
  }

  // Formatting the date according to locale
  let dateString = date.toLocaleDateString(locale.value, options)

  // Capitalizing the first letter of the weekday and adjusting format
  dateString = dateString.charAt(0).toUpperCase() + dateString.slice(1)
  dateString = dateString.replace(',', '') // Remove any commas

  // Remove the period if it exists at the end
  if (dateString.endsWith('.')) {
    dateString = dateString.slice(0, -1)
  }

  return dateString
})

const sortedAndFilledCourses: ComputedRef<(MenuCourse | null)[]> = computed(() => {
  const courses = planningStore.selectedPlannedMenu?.menu_courses || []
  const sortedCourses = [...courses].sort((a, b) => a.order - b.order)

  // Ensure all three course types are present
  const courseTypes = [1, 2, 3] // Corresponds to Entrée, Plat principal, Dessert
  return courseTypes.map((type) => {
    const found = sortedCourses.find((course) => course.order === type)
    return found || null
  })
})

function openShareModal() {
  shareModalVisible.value = true
}

const mainDish = computed(() => {
  const dish = planningStore.selectedPlannedMenu?.menu_courses.find(
    (course) => course.order === 2
  )?.dish
  if (!dish) return null

  // if (dish.selected_image === 'none') {
  //   return {
  //     ...dish,
  //     imageUrl: '',
  //   }
  // }

  const imageUrl = dish.image_url_gastro || dish.image_url_bistro || dish.image_url_user
  return {
    ...dish,
    imageUrl: imageUrl || '',
  }
})

const imageDataUrl = ref('') // Reactive property to hold the image data URL

const shareData = ref({
  title: '',
  date: '',
  dish: null as Dish | null,
  imageSrc: '',
  price: ''
})

const shareMenu = async (type: string) => {
  loadingShare.value = true
  // Set generic data based on sharing type
  if (type === 'storyStarter') {
    const starterCourse = sortedAndFilledCourses.value[0];
    if (!starterCourse?.dish) {
      toast.add({
        severity: 'error',
        summary: t('common.error'),
        detail: t('planning.plannedMenu.noStarter'),
        life: 3000,
      });
      return;
    }

    // Fetch starter image if it has a selected image
    if (starterCourse.dish.selected_image && starterCourse.dish.selected_image !== 'none') {
      const { data, error } = await fetchData(
        `${envStore.apiUrl}/dishes/${starterCourse.dish.id}/images/${starterCourse.dish.selected_image}`
      );

      if (error) {
        console.error('Error fetching starter image:', error);
          toast.add({
            severity: 'error',
            summary: t('common.error'),
            detail: t('planning.plannedMenu.noStarter'),
            life: 3000,
          });
        return;
      }

      if (data && data.image) {
        starterImageSrc.value = data.image;
      }
    }

    shareData.value = {
      title: plannedMenuTitle.value,
      date: formattedDate.value,
      dish: starterCourse.dish,
      imageSrc: starterImageSrc.value,
      price: formattedPrice.value
    }
  } else if (type === 'storyMainDish') {
    shareData.value = {
      title: plannedMenuTitle.value,
      date: formattedDate.value,
      dish: mainDish.value,
      imageSrc: mainDishImageSrc.value,
      price: formattedPrice.value
    }
  } else if (type === 'storyDessert') {
    const dessertCourse = sortedAndFilledCourses.value[2];
    if (!dessertCourse?.dish) {
      toast.add({
        severity: 'error',
        summary: t('common.error'),
        detail: t('planning.plannedMenu.noDessert'),
        life: 3000,
      });
      return;
    }

    // Fetch dessert image if it has a selected image
    if (dessertCourse.dish.selected_image && dessertCourse.dish.selected_image !== 'none') {
      const { data, error } = await fetchData(
        `${envStore.apiUrl}/dishes/${dessertCourse.dish.id}/images/${dessertCourse.dish.selected_image}`
      );

      if (error) {
        console.error('Error fetching dessert image:', error);
          toast.add({
            severity: 'error',
            summary: t('common.error'),
            detail: t('planning.plannedMenu.noDessert'),
            life: 3000,
          });
        return;
      }

      if (data && data.image) {
        dessertImageSrc.value = data.image;
      }
    }

    shareData.value = {
      title: plannedMenuTitle.value,
      date: formattedDate.value,
      dish: dessertCourse.dish,
      imageSrc: dessertImageSrc.value,
      price: formattedPrice.value
    }
  }

  // Wait for next tick to ensure template is updated
  await nextTick()

  // Add a small delay to ensure image is loaded
  await new Promise(resolve => {
    const img = new Image()
    img.onload = () => {
      resolve(true)
    }
    img.src = shareData.value.imageSrc
  })

  // Now capture the image
  shareMenuImage(type)
}

function addOrUpdateMenuCourse(menuCourse: MenuCourse) {
  if (!planningStore.selectedPlannedMenu) return
  menuCourse.planned_menu_id = planningStore.selectedPlannedMenu.id
  planningStore.addOrUpdateMenuCourse(menuCourse, router)
}

function getOrderedIndex(index: number): 1 | 2 | 3 {
  return (((index - 1) % 3) + 1) as 1 | 2 | 3
}

function togglePopUpMenu(event: MouseEvent) {
  popupMenu.value.toggle(event)
}
function togglePopUpShareMenu(event: MouseEvent) {
  popupShareMenu.value.toggle(event)
}

const confirmDeletePlannedMenu = () => {
  confirmDialog.require({
    message: t('planning.plannedMenu.delete.message'),
    header: t('planning.plannedMenu.delete.title'),
    icon: 'pi pi-exclamation-triangle',
    rejectClass: 'p-button-secondary p-button-outlined',
    rejectLabel: t('planning.plannedMenu.delete.cancel'),
    acceptLabel: t('planning.plannedMenu.delete.confirm'),
    accept: () => {
      if (planningStore.selectedPlannedMenu)
        planningStore.deletePlannedMenu(planningStore.selectedPlannedMenu.id, router)
    },
    reject: () => { },
  })
}

// Call fetchMainDishImage when mainDish changes

const fetchMainDishImage = async () => {
  if (mainDish.value && mainDish.value.id && mainDish.value.selected_image) {
    const { data, error } = await fetchData(
      `${envStore.apiUrl}/dishes/${mainDish.value.id}/images/${mainDish.value.selected_image}`
    )

    if (error) {
      console.error('Error fetching image:', error)
      // Handle error (e.g., set a default image or show an error message)
    } else if (data && data.image) {
      mainDishImageSrc.value = data.image
    }
  }
}

watch(mainDish, fetchMainDishImage, { immediate: true })

const countUpperCase = (str: string) => {
  return str.split('').filter((char) => char === char.toUpperCase() && char !== char.toLowerCase())
    .length
}

const titleLengthError = computed(() => {
  const uppercaseCount = countUpperCase(plannedMenuTitle.value)
  if (plannedMenuTitle.value.length > MAX_TITLE_LENGTH || uppercaseCount > MAX_UPPERCASE_LENGTH) {
    return t('planning.plannedMenu.titleTooLongWarning')
  }
  return ''
})

const checkTitleLength = () => {
  const uppercaseCount = countUpperCase(plannedMenuTitle.value)
  isTitleTooLong.value =
    plannedMenuTitle.value.length > MAX_TITLE_LENGTH || uppercaseCount > MAX_UPPERCASE_LENGTH
}

const formattedUrl = (url) => {
  return url.replace(/^https?:\/\//, '')
}

watch(plannedMenuTitle, checkTitleLength)

const shareFormats = [
  {
    name: 'storyStarter',
    id: 'share-story-one-dish',
    width: 1080,
    height: 1920,
    description: 'Story image with starter and image',
  },
  {
    name: 'storyMainDish',
    id: 'share-story-one-dish',
    width: 1080,
    height: 1920,
    description: 'Story image with main dish and image',
  },
  {
    name: 'storyDessert',
    id: 'share-story-one-dish',
    width: 1080,
    height: 1920,
    description: 'Story image with dessert image',
  },
  {
    name: 'storyFull',
    id: 'share-story-full',
    width: 1080,
    height: 1920,
    description: 'Story image with all dishes and image',
  },
  {
    name: 'storyWNutri',
    id: 'share-story-nutri',
    width: 1080,
    height: 1920,
    description: 'Story image with main dish and nutritional facts',
  },
];

const loadingShare = ref(false)

function shareMenuImage(formatName: string) {
  const selectedFormat = shareFormats.find((format) => format.name === formatName);
  if (!selectedFormat) {
    console.error('Format non trouvé');
    return;
  }

  const element = document.getElementById(selectedFormat.id);
  if (!element) {
    console.error('Élément non trouvé pour le format', formatName);
    return;
  }

  loadingShare.value = true;
  element.style.display = 'block'; // Unhide the element for capturing

  html2canvas(element, { scale: 1, useCORS: true, width: selectedFormat.width, height: selectedFormat.height })
    .then((canvas) => {
      element.style.display = 'none'; // Hide the element again
      imageDataUrl.value = canvas.toDataURL('image/png'); // Store the image data URL
      openShareModal(); // Open the modal to show the image
    })
    .catch((error) => {
      console.error('Erreur lors de la capture du canvas:', error);
      element.style.display = 'none'; // Ensure the element is hidden if there's an error
    })
    .finally(() => {
      loadingShare.value = false;
    });
}

const placeholderMenu = ref()
const placeholderMenuItems = ref([
  {
    label: t('planning.plannedMenu.uploadImage'),
    icon: 'pi pi-upload',
    command: () => {
      uploadImage()
    },
  },
  {
    label: t('planning.plannedMenu.generateImage'),
    icon: 'pi pi-image',
    command: () => {
      generateImage()
    },
  },
])

const fileInput: Ref<HTMLInputElement | null> = ref(null)

function togglePlaceholderMenu(event) {
  placeholderMenu.value.toggle(event)
}

function uploadImage() {
  fileInput.value?.click()
}

async function handleFileUpload(event: Event) {
  const files = (event.target as HTMLInputElement).files
  if (files && files.length > 0 && mainDish.value) {
    await dishesStore.uploadUserImage(mainDish.value.id, files[0], toast)
    await fetchMainDishImage()
  }
}

function generateImage() {
  if (!mainDish.value) {
    toast.add({
      severity: 'error',
      summary: t('common.error'),
      detail: t('planning.plannedMenu.noMainDish'),
      life: 3000,
    })
    return
  }

  dishesStore.generateImages(mainDish.value.id, toast)
  router.push({ path: `/menu/dishes/${mainDish.value.id}/images` })
}

</script>

<template>
  <main>
    <Dialog v-if="mainDish" v-model:visible="isImageModalVisible" modal style="max-width: 90%" class="sm:max-w-3xl">
      <DishImage v-if="mainDish" :dishId="mainDish?.id" :type="mainDish?.selected_image" :alt="mainDish?.name"
        ref="dishImageRef" size="full" />
    </Dialog>
    <Menu ref="popupMenu" :model="popUpMenuItems" :popup="true" :pt="{ submenuHeader: { class: '!p-0' } }"
      :pt-options="{ mergeProps: true }" />
    <Menu ref="popupShareMenu" :model="shareMenuItems" :popup="true" :pt="{ submenuHeader: { class: '!p-0' } }"
      :pt-options="{ mergeProps: true }" />
    <div class="flex flex-col gap-6">
      <div class="flex justify-end gap-6 items-top" v-if="!planningStore.dishToPlan">
        <Button v-if="shareMenuItems.length > 0" rounded link :pt="{ root: { class: '!p-1.5 !w-8 !h-8' } }"
          :pt-options="{ mergeProps: true }" @click="togglePopUpShareMenu">
          <template #icon>
            <i v-if="loadingShare" class="pi pi-spin pi-spinner"></i>
            <ButtonShare v-else />
          </template>
        </Button>
        <Button link rounded :pt="{ root: { class: '!p-1.5 !w-8 !h-8 rotate-90' } }" :pt-options="{ mergeProps: true }"
          @click="togglePopUpMenu">
          <template #icon>
            <ButtonMenu />
          </template>
        </Button>
      </div>
      <!-- <div>
      Header with info
    </div> -->
      <div class="flex justify-center my-5" v-if="mainDish">
        <DishImage v-if="mainDish.imageUrl" :dishId="mainDish?.id" :type="mainDish?.selected_image"
          :alt="mainDish?.name" ref="dishImageRef" @click="isImageModalVisible = true" class="cursor-pointer" />
        <div v-else class="w-60 h-60 rounded-3xl bg-primary-100 flex items-center justify-center relative">
          <button class="p-2 bg-accent text-white rounded-full aspect-square w-10 flex justify-center items-center"
            @click="togglePlaceholderMenu">
            <i class="pi pi-plus" style="font-size: 1rem"></i>
          </button>
          <Menu ref="placeholderMenu" :model="placeholderMenuItems" :popup="true" />
        </div>
      </div>
      <!-- <Button link rounded :pt="{ root: { class: '!p-1.5 !w-8 !h-8 rotate-90' } }" :pt-options="{ mergeProps: true }"
        @click="togglePopUpMenu">
        <template #icon>
          <ButtonMenu />
        </template>
      </Button>
      <Menu ref="popupMenu" id="overlay_menu" :model="items" :popup="true" :pt="{ submenuHeader: { class: '!p-0' } }"
        :pt-options="{ mergeProps: true }" /> -->
      <div>
        <p class="text-lg text-center">{{ formattedDate }}</p>
      </div>

      <!-- <p class="text-lg text-center">{{ planningStore.selectedPlannedMenu?.name }}</p> -->
      <div class="flex flex-col gap-4">
        <div class="grid items-center grid-cols-12 gap-2">
          <div class="col-span-2 min-w-4">
            <p>
              {{ t('planning.plannedMenu.titleLabel') }}
            </p>
          </div>
          <div class="flex justify-center col-span-9 min-w-52">
            <div class="flex flex-col w-full">
              <InputText id="plannedMenuTitle" v-model="plannedMenuTitle" @blur="updatePlannedMenuTitle"
                class="w-full" />
              <small v-if="titleLengthError" class="text-xs text-accent">
                {{ titleLengthError }}
              </small>
            </div>
          </div>
        </div>
        <div class="grid items-center grid-cols-12 gap-2">
          <div class="col-span-2 min-w-4">
            <p>
              {{ t('planning.plannedMenu.priceLabel') }}
            </p>
          </div>
          <div class="flex justify-center col-span-9 min-w-52">
            <div class="flex flex-col w-full">
              <InputNumber input-id="plannedMenuPrice" v-model="plannedMenuPrice" @blur="updatePlannedMenuPrice"
                :min="0" :step="0.01" mode="currency" currency="CHF" />
            </div>
          </div>
        </div>
      </div>

      <div v-for="(menuCourse, order) in sortedAndFilledCourses" :key="menuCourse?.id">
        <PlannedMenuLine :order="getOrderedIndex(order + 1)" :menu-course="menuCourse"
          @add-or-update-menu-course="addOrUpdateMenuCourse" @delete-menu-course="planningStore.deleteMenuCourse" />
        <!-- <div v-else class="flex justify-center p-6 text-center" >
          <button @click="console.log('add course')"
            class="flex items-center justify-center w-10 p-2 text-white rounded-full bg-accent aspect-square">
            
            <i class="pi pi-plus" style="font-size: 1rem"></i>
          </button>
        </div> -->
      </div>
    </div>

    <Dialog v-if="sortedAndFilledCourses" :header="t('socialNetworks.share')" v-model:visible="shareModalVisible" modal
      style="max-width: 90%" class="sm:max-w-3xl">
      <ShareCard :image="imageDataUrl" />
    </Dialog>
    <!-- <div id="share-item" class="menu-card w-[1080px] h-[1080px] flex flex-col justify-between"> -->
    <!-- class="relative menu-card w-[1080px] h-[1920px] border-2 border-slay-200 hidden absolute -left-[5000px] top-0"> -->
    <div id="share-story-one-dish"
      class="relative menu-card w-[1080px] h-[1920px] border-2 border-slay-200 hidden absolute -left-[5000px] top-0">
      <div class="h-[150px] mt-[135px] px-24 w-[1080px] flex flex-col justify-center">
        <h1 class="text-7xl text-primary-900 line-clamp-1 leading-tight">{{ shareData.title }}</h1>
        <h2 class="text-4xl text-primary-900">{{ formattedDate }}</h2>
      </div>
      <div class="w-[900px] mx-auto">
        <div class="relative w-full aspect-square mx-auto  rounded-3xl mb-20">
          <div
            class="rounded-full bg-primary-100 absolute -top-16 -right-[4.5rem] w-[180px] aspect-square flex justify-center align-middle">
          </div>
          <div
            class="rounded-full bg-white absolute -top-16 -right-16 w-44 aspect-square flex justify-center align-middle">
            <img v-if="user?.restaurants[0]?.logo" :src="user?.restaurants[0]?.logo"
              class="w-32 h-32 object-contain m-auto p-2" />
          </div>
          <img v-if="shareData.imageSrc" :src="shareData.imageSrc" :alt="shareData.dish?.name"
            class="object-cover w-full h-full rounded-3xl" />

        </div>
        <div class="flex max-w-[900px] h-[430px] mx-auto flex-col items-start justify-center gap-11 self-stretch mb-20">
          <p class="w-11/12 mx-auto text-6xl italic font-bold leading-normal text-center font-garamond line-clamp-3">
            {{ shareData.dish?.name }}
          </p>
          <div v-if="plannedMenuPrice > 0" class="h-0.5 w-14 bg-gray-300 my-2 mx-auto">
          </div>
          <p v-if="plannedMenuPrice > 0" class="font-garamond text-center text-6xl italic font-bold w-full">{{
            shareData.price }}</p>
        </div>
        <footer class="absolute left-0 flex flex-row items-center justify-center w-full mx-auto gap-7 bottom-16">
          <div class="absolute left-12 bottom-0 flex flex-col items-center h-28">
            <img src="@/assets/images/avatar-head-transparent.svg" alt="Logo restaurant"
              class="object-contain p-1 w-20 h-20" />
            <p class="w-full text-2xl text-center text-primary-700 leading-tight">Powered by</p>
            <p class="w-full text-2xl text-center font-bold text-primary-700">GastronomIA</p>
          </div>
          <div v-if="user?.restaurants[0]?.logo" class="w-28 h-28">
            <img v-if="user?.restaurants[0]?.logo" :src="user?.restaurants[0]?.logo" alt="Logo restaurant"
              class="object-contain w-full h-full p-1" />
          </div>
          <div class=" flex flex-col items-start text-2xl text-left shrink-0">
            <p class="w-full text-4xl italic font-garamond">{{ user?.restaurants[0]?.name }}</p>
            <p v-if="user?.restaurants[0]?.phone_number">
              {{ user?.restaurants[0]?.phone_number }}
            </p>
            <p v-if="user?.restaurants[0]?.website_url">
              {{ formattedUrl(user?.restaurants[0]?.website_url) }}
            </p>
          </div>
          <!-- <p class="w-full text-left ">Gastronomia</p> -->
        </footer>
      </div>

      <!-- class="menu-card w-[1080px] h-[1920px] flex flex-col justify-between hidden absolute -left-[5000px] top-0"> -->
      <!-- <div class=""> -->
    </div>
    <!-- class="relative menu-card w-[1080px] h-[1920px] border-2 border-slay-200 hidden absolute -left-[5000px] top-0"> -->
    <div id="share-story-full"
      class="relative menu-card w-[1080px] h-[1920px] border-2 border-slay-200 hidden absolute -left-[5000px] top-0">
      <div class="h-[150px] mt-[135px] px-24 w-[1080px] flex flex-col justify-center">
        <h1 class="text-7xl text-primary-900 line-clamp-1 leading-tight">{{ plannedMenuTitle }}</h1>
        <h2 class="text-4xl text-primary-900">{{ formattedDate }}</h2>
      </div>
      <div class="w-[900px] mx-auto">
        <div class="relative w-full mx-auto rounded-3xl mb-8">
          <div
            class="rounded-full bg-primary-100 absolute -top-16 -right-[4.5rem] w-[180px] aspect-square flex justify-center align-middle">
          </div>
          <div
            class="rounded-full bg-white absolute -top-16 -right-16 w-44 aspect-square flex justify-center align-middle">
            <img v-if="user?.restaurants[0]?.logo" :src="user?.restaurants[0]?.logo"
              class="w-32 h-32 object-contain m-auto p-2" />
          </div>
          <img :src="mainDishImageSrc" :alt="mainDish?.name" class="object-cover w-full h-[780px] rounded-3xl" />

        </div>
        <div class="flex max-w-[900px] h-[600px] mx-auto flex-col items-start justify-center gap-5 self-stretch mb-10">
          <div class="flex flex-col justify-between w-full gap-4">
            <!-- starter -->
            <p class="w-11/12 mx-auto text-5xl italic font-bold leading-tight text-center font-garamond line-clamp-2">
              {{ sortedAndFilledCourses[0]?.dish?.name ?? sortedAndFilledCourses[0]?.name ?? '***' }}
            </p>
            <div class="h-0.5 w-10 bg-gray-300 my-2 mx-auto"></div>

            <!-- Main dish -->
            <p class="w-11/12 mx-auto text-6xl italic font-bold leading-tight text-center font-garamond line-clamp-2">
              {{ sortedAndFilledCourses[1]?.dish?.name ?? sortedAndFilledCourses[1]?.name ?? '***' }}
            </p>
            <div class="h-0.5 w-10 bg-gray-300 my-2 mx-auto"></div>

            <!-- Dessert -->
            <p class="w-11/12 mx-auto text-5xl italic font-bold leading-tight text-center font-garamond line-clamp-2">
              {{ sortedAndFilledCourses[2]?.dish?.name ?? sortedAndFilledCourses[2]?.name ?? '***' }}
            </p>
          </div>
          <div v-if="plannedMenuPrice > 0" class="h-0.5 w-14 bg-gray-300 mx-auto my-1"></div>
          <p v-if="plannedMenuPrice > 0" class="font-garamond text-center text-6xl italic font-bold w-full">
            {{ formattedPrice }}
          </p>
        </div>
        <footer class="absolute left-0 flex flex-row items-center justify-center w-full mx-auto gap-7 bottom-6">
          <div class="absolute left-12 bottom-0 flex flex-col justify-end items-center h-28">
            <img src="@/assets/images/avatar-head-transparent.svg" alt="Logo restaurant"
              class="object-contain p-1 w-20 h-20" />
            <p class="w-full text-2xl text-center text-primary-700 leading-tight">Powered by</p>
            <p class="w-full text-2xl text-center font-bold text-primary-700">GastronomIA</p>
          </div>
          <div v-if="user?.restaurants[0]?.logo" class="w-28 h-28">
            <img v-if="user?.restaurants[0]?.logo" :src="user?.restaurants[0]?.logo" alt="Logo restaurant"
              class="object-contain w-full h-full p-1" />
          </div>
          <div class="flex flex-col items-start text-2xl text-left shrink-0">
            <p class="w-full text-4xl italic font-garamond">{{ user?.restaurants[0]?.name }}</p>
            <p v-if="user?.restaurants[0]?.phone_number">
              {{ user?.restaurants[0]?.phone_number }}
            </p>
            <p v-if="user?.restaurants[0]?.website_url">
              {{ formattedUrl(user?.restaurants[0]?.website_url) }}
            </p>
          </div>
        </footer>
      </div>
    </div>
    <div id="share-story-nutri"
      class="relative menu-card w-[1080px] h-[1920px] border-2 border-slay-200 hidden absolute -left-[5000px] top-0">
      <div class="bg-[#f09f26] h-[180px] px-24 w-[1080px] flex flex-col justify-center">
        <h1 class="text-7xl text-white line-clamp-1 leading-none">{{ plannedMenuTitle }}</h1>
        <h2 class="text-4xl text-white">{{ formattedDate }}</h2>
      </div>
      <div class="w-[900px] mx-auto mt-24">
        <div class="relative w-[400px] aspect-square mx-auto  rounded-3xl mb-15">
          <img src="@/assets/images/avatar-head-transparent.svg" class="absolute -top-16 -right-7 w-32 object-cover" />
          <img :src="mainDishImageSrc" :alt="mainDish?.name" class="object-cover w-full h-full rounded-3xl" />

        </div>
        <div class="flex max-w-[900px] h-[430px] mx-auto flex-col items-start justify-center gap-11 self-stretch mb-20">
          <p class="w-11/12 mx-auto text-6xl italic font-bold leading-normal text-center font-garamond line-clamp-3">
            {{ mainDish?.name }}
          </p>
          <div v-if="plannedMenuPrice > 0" class="h-0.5 w-14 bg-gray-300 my-2 mx-auto">
          </div>
          <p v-if="plannedMenuPrice > 0" class="font-garamond text-center text-6xl italic font-bold w-full">{{
            formattedPrice }}</p>
        </div>
        <div>
          <NutritionalValuesCard :dish="mainDish" />
        </div>
        <footer class="absolute left-0 flex flex-row items-center justify-center w-full mx-auto gap-7 bottom-16">
          <div v-if="user?.restaurants[0]?.logo" class="w-28 h-28">
            <img v-if="user?.restaurants[0]?.logo" :src="user?.restaurants[0]?.logo" alt="Logo restaurant"
              class="object-contain w-full h-full p-1" />
          </div>
          <div class="flex flex-col items-start text-2xl text-left shrink-0">
            <p class="w-full text-4xl italic font-garamond">{{ user?.restaurants[0]?.name }}</p>
            <p v-if="user?.restaurants[0]?.phone_number">
              {{ user?.restaurants[0]?.phone_number }}
            </p>
            <p v-if="user?.restaurants[0]?.website_url">
              {{ formattedUrl(user?.restaurants[0]?.website_url) }}
            </p>
          </div>
          <!-- <p class="w-full text-left ">Gastronomia</p> -->
        </footer>
      </div>

      <!-- class="menu-card w-[1080px] h-[1920px] flex flex-col justify-between hidden absolute -left-[5000px] top-0"> -->
      <!-- <div class=""> -->
    </div>
    <div id="share-item-2"
      class="hidden menu-card w-[1080px] h-[1080px] flex flex-col justify-between hidden absolute -left-[5000px] top-0">
      <div class="bg-[#f09f26] py-14 px-16">
        <h1 class="leading-tight text-white text-7xl line-clamp-1">{{ plannedMenuTitle }}</h1>
        <h2 class="text-4xl text-white">{{ formattedDate }}</h2>
      </div>

      <!-- <ul class="text-center text-4xl flex flex-col justify-between max-h-[700px] px-16"> -->
      <ul :class="[
        'text-center flex flex-col justify-between px-28 py-4',
        mainDish && mainDish.imageUrl ? 'text-3xl h-[400px]' : 'text-4xl h-[700px]',
      ]">
        <!-- <li v-for="(menuCourse, index) in sortedAndFilledCourses" :key="menuCourse?.id"
        class="flex flex-col justify-around flex-grow"> -->
        <li v-for="(menuCourse, index) in sortedAndFilledCourses" :key="menuCourse?.id"
          class="flex flex-col justify-around flex-grow">
          <p class="leading-tight" :class="[mainDish && mainDish.imageUrl ? 'line-clamp-2' : 'line-clamp-3']">
            {{ menuCourse?.dish?.name ?? menuCourse?.name ?? '***' }}
          </p>
          <!-- Show the divider line only if it's not the last item in the list -->
          <div v-if="index < sortedAndFilledCourses.length - 1" class="h-0.5 w-10 bg-gray-300 my-2 mx-auto"></div>
        </li>
      </ul>
      <div v-if="mainDish && mainDish.imageUrl" class="flex flex-1 justify-center my-5 h-[360px] max-w-[800px] mx-auto">
        <img :src="mainDishImageSrc" :alt="mainDish?.name" class="object-contain w-full h-full rounded-3xl" />
      </div>
      <footer class="flex flex-row items-center mx-auto mb-7 gap-14">
        <!-- <div class="w-24" v-if="">
          Image
        </div> -->
        <div class="text-2xl text-left">
          <p class="font-bold">{{ user?.restaurants[0]?.name }}</p>
          <!-- <p>+41 123 45 67</p>
          <p>restaurant-le-soleil.ch</p> -->
        </div>
        <!-- <p class="w-full text-left">Gastronomia</p> -->
      </footer>
    </div>
    <input ref="fileInput" type="file" name="dish-image" id="dish-image"
      accept="image/jpeg, image/jpg, image/png, image/webp, image/heic" @change="handleFileUpload"
      class="hidden mb-4" />
  </main>
</template>

<style scoped></style>
