<template>
  <div class="card-container flex w-full rounded-xl bg-primary-100 relative shadow-clickable-card">
    <div class="w-full py-3 pl-5 pr-2">
      <slot /> 
    </div>
    <div class="absolute right-3 flex items-center justify-center transform -translate-y-1/2 top-1/2">
      <div class="flex items-center justify-center align-middle w-6 aspect-square rounded-full bg-primary-600 ">
        <i class="pi pi-angle-right text-white"></i>
      </div>
    </div>
  </div>
</template>

<style scoped>
.card-container {
  position: relative;
  overflow: hidden;
}
</style>
