import ProfileTab from '@/components/Profile/MyProfile/OldProfileTab.vue'
import ProfileView from '@/components/Profile/MyProfile/ProfileView.vue'
import RestaurantTab from '@/components/Profile/MyProfile/RestaurantTab.vue'
import RestaurantView from '@/components/Profile/MyProfile/RestaurantView.vue'
import DishCreationFormView from '@/views/Profile/DishCreationFormView.vue'
import FavoritesView from '@/views/MyRecipes/FavoritesView.vue'
import GenerationHistoryView from '@/views/MyRecipes/GenerationHistoryView.vue'
import ProfileHomeView from '@/views/Profile/ProfileHomeView.vue'
import MyScansView from '@/views/MyRecipes/MyScansView.vue'
import MyProfileView from '@/views/Profile/MyProfileView.vue'
import SuppliersView from '@/views/Profile/SuppliersView.vue'
import OrdersView from '@/views/Profile/OrdersView.vue'
import OrderDetailView from '@/views/Profile/OrderDetailView.vue'

const profileRoutes = [
  {
    path: '/profile',
    name: 'profile',
    meta: {
      headerConfig: {
        headerLabel: 'profile',
        hide: false,
        showBack: true,
        showProfile: false,
      },
      footerConfig: {
        hide: true,
      },
      securityConfig: {
        requireAuthentication: true,
        rolesAllowed: ['common', 'admin'],
      },
      containerMargins: true,
    },
    component: ProfileHomeView,
  },
  // {
  //   path: '/profile/generation-history',
  //   name: 'generation-history',
  //   meta: {
  //     headerConfig: {
  //       headerLabel: 'history',
  //       hide: false,
  //       showBack: true,
  //       showProfile: false,
  //     },
  //     footerConfig: {
  //       hide: true,
  //     },
  //     securityConfig: {
  //       requireAuthentication: true,
  //       rolesAllowed: ['common', 'admin'],
  //     },
  //     containerMargins: true,
  //   },
  //   component: GenerationHistoryView,
  // },
  // {
  //   path: '/profile/my-scans',
  //   name: 'my-scans',
  //   meta: {
  //     headerConfig: {
  //       headerLabel: 'myScans',
  //       hide: false,
  //       showBack: true,
  //       showProfile: false,
  //     },
  //     footerConfig: {
  //       hide: true,
  //     },
  //     securityConfig: {
  //       requireAuthentication: true,
  //       rolesAllowed: ['common', 'admin'],
  //     },
  //   },
  //   component: MyScansView,
  // },
  {
    path: '/profile/my-profile',
    name: 'my-profile',
    meta: {
      headerConfig: {
        headerLabel: 'myProfile',
        hide: false,
        showBack: true,
        showProfile: false,
      },
      footerConfig: {
        hide: true,
      },
      securityConfig: {
        requireAuthentication: true,
        rolesAllowed: ['common', 'admin'],
      },
      containerMargins: true,
    },
    component: ProfileView,
  },
  {
    path: '/profile/my-restaurant',
    name: 'my-restaurant',
    meta: {
      headerConfig: {
        headerLabel: 'myRestaurant',
        hide: false,
        showBack: true,
        showProfile: false,
      },
      footerConfig: {
        hide: true,
      },
      securityConfig: {
        requireAuthentication: true,
        rolesAllowed: ['common', 'admin'],
      },
      containerMargins: true,
    },
    component: RestaurantView,
  },
  {
    path: '/profile/suppliers',
    name: 'suppliers',
    meta: {
      headerConfig: {
        headerLabel: 'suppliers',
        hide: false,
        showBack: true,
        showProfile: false,
      },
      footerConfig: {
        hide: true,
      },
      securityConfig: {
        requireAuthentication: true,
        rolesAllowed: ['common', 'admin'],
      },
      containerMargins: true,
    },
    component: SuppliersView,
  },
  {
    path: '/profile/orders',
    name: 'orders-history',
    meta: {
      headerConfig: {
        headerLabel: 'history',
        hide: false,
        showBack: true,
        showProfile: false,
      },
      footerConfig: {
        hide: true,
      },
      securityConfig: {
        requireAuthentication: true,
        rolesAllowed: ['common', 'admin'],
      },
      containerMargins: true,
    },
    component: OrdersView,
  },
  {
    path: '/profile/orders/:orderId',
    name: 'order-detail',
    meta: {
      headerConfig: {
        headerLabel: 'orderDetail',
        hide: false,
        showBack: true,
        showProfile: false,
      },
      footerConfig: {
        hide: true,
      },
      securityConfig: {
        requireAuthentication: true,
        rolesAllowed: ['common', 'admin'],
      },
      containerMargins: true,
    },
    component: OrderDetailView,
  },
]

export default profileRoutes
